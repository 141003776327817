import axios from "axios"
import {groupBy} from "lodash"
import moment from "moment"
import {requestHeaderInterceptor} from "./interceptors"
import {authHeader} from "./auth-header"

import fetchTools from "./fetchTools"

const sortByDateDesc = (list) => {
    const sortedByDate = groupBy(list, (tag) => {
        return moment(tag.date).startOf('day').format()
    })
    let sortedList = []
    Object.keys(sortedByDate)
        .sort()
        .reverse()
        .forEach(key => {
            sortedList.push({
                key: key,
                value: sortedByDate[key]
            })
        })
    return sortedList
}

export const getPhotoGallery = async (projectId, tags, searchText, fromDate, toDate, description = '', showPhotosWithoutTagsOnly = false, user = '', useOrTags = false, page = 1) => {

    const limit = 100;

    let url = `api/images?page=${page}&per_page=${limit}&project=${projectId}`

    if (tags.length > 0) {
        url = url.concat(`&tags=${tags.map(t => t._id).join(',')}`)
    }
    if (searchText.length > 2) {
        url = url.concat(`&name=${searchText}`)
    }
    if (fromDate) {
        url = url.concat(`&since=${moment(fromDate, 'YYYY-MM-DD')}`)
    }
    if (toDate) {
        url = url.concat(`&until=${moment(toDate, 'YYYY-MM-DD')}`)
    }

    if (description.length > 2) {
        url = url.concat(`&imageComment=${description}`)
    }

    if (user) {
        url = url.concat(`&user=${user.value}`)
    }

    if (useOrTags) {
        url = url.concat(`&useOrTags=${useOrTags}`)
    }

    if (showPhotosWithoutTagsOnly) {
        url = url.concat(`&showPhotosWithoutTagsOnly=${showPhotosWithoutTagsOnly}`)
    }

    const response = await fetchTools.get(url);

    return {
        images: sortByDateDesc(response.images),
        nextPage: response.nextPage
    }
}

export const downloadImage = (image) => {
    const a = document.createElement('a')
    a.href = image.imageUrl
    a.download = image.imageKey
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export const updateImageTags = async (image, tags) => {
    const url = `api/images/${image._id}/tags`
    return await fetchTools.put(url, {tags})
}

export const deleteImage = async (image) => {
    const url = `api/image/${image._id}`
    console.log(image)
    return await fetchTools.delete(url)
}

export const exportPDFForSelectedPhotos = async (images, data) => {
    const url = `api/images/export-pdf`
    const fetchAPI = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 60000,
        crossDomain: true,
        headers: authHeader(),
        responseType: 'blob'
    });

    fetchAPI.interceptors.request.use(requestHeaderInterceptor, (error) => {
            Promise.reject(error)
        }
    );
    return await fetchAPI.post(url, {
        tzOffset: 7,
        images,
        ...data
    })
}

export const toggleShowInReportsFlag = async (selectedImages) => {
    const url = `api/images/toggle-show-in-report-flag`
    const fetchAPI = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 60000,
        crossDomain: true,
        headers: authHeader(),
    });

    fetchAPI.interceptors.request.use(requestHeaderInterceptor, (error) => {
            Promise.reject(error)
        }
    );

    return await fetchAPI.post(url, {
        selectedImages
    })
}
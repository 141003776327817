import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Dock } from "react-dock";
import classnames from "classnames";
import {
  Button,
  Input,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { userCan, userCant } from "../../services/Authorization/authService";
import PropTypes from "prop-types";

const mapStateToProps = (state) => ({
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
  user: state.auth.user,
  taskData: state.allOtherReducers.taskData || []
});

const ConnectedAddTaskTable = ({ 
  t, 
  user, 
  projectId, 
  taskData = [], 
  getSomeTasks = () => {},
  refreshTask 
}) => {
  const cancelTokenRef = useRef(null);
  
  const [state, setState] = useState({
    taskToAddValue: '',
    editTask: null,
    showForm: false,
    errors: {},
    isLoading: false,
    tasks: []
  });

  const fetchTasks = async () => {
    if (!projectId) {
      console.log('No projectId provided');
      return;
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/task/project/${projectId}`,
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );

      console.log('API Response for project:', projectId, response.data);

      const tasks = Array.isArray(response.data) ? response.data : [];
      
      console.log('Processed tasks:', tasks);

      setState(prev => ({
        ...prev,
        tasks,
        isLoading: false
      }));

      if (typeof getSomeTasks === 'function') {
        getSomeTasks(tasks);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setState(prev => ({
        ...prev,
        tasks: [],
        isLoading: false,
        errors: { general: `No tasks found for project ${this?.props?.project}` }
      }));
    }
  };

  useEffect(() => {
    console.log('ProjectId changed:', projectId);
    fetchTasks();
  }, [projectId]);

  useEffect(() => {
    if (Array.isArray(taskData) && taskData.length > 0) {
      setState(prev => ({
        ...prev,
        tasks: taskData
      }));
    }
  }, [taskData]);

  const renderEditable = (cellInfo) => (
    <div>{cellInfo.value}</div>
  );

  const renderActiveButtons = (row) => {
    if (userCant(user, 'can_manage_tasks_and_materials')) return null;

    return (
      <div>
        <Button
          onClick={() => setState(prev => ({
            ...prev,
            showForm: true,
            editTask: row.original,
            errors: {},
            taskToAddValue: row.original.taskName
          }))}
          size="xs"
          className="btn-icon btn-neutral btn btn-info"
        >
          <i className="fa fa-edit" />
        </Button>
        <Button
          color="danger"
          size="sm"
          className="btn-icon btn-neutral btn btn-info"
          onClick={() => deleteTaskByTaskId(row.original._id)}
        >
          <i className="fa fa-remove" />
        </Button>
      </div>
    );
  };

  const deleteTaskByTaskId = async (taskId) => {
    if (userCant(user, 'can_manage_tasks_and_materials')) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/task/${taskId}`, {
        headers: { 'Authorization': localStorage.jwtToken }
      });
      getSomeTasks();
    } catch (error) {
      handleApiError(error);
    }
  };

  const createNewTask = async (element) => {
    setState(prev => ({ ...prev, errors: {} }));

    if (state.editTask) {
      return saveRowData();
    }

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/task/`,
        {
          createdDate: moment(new Date()).format(),
          taskName: element,
          projectId
        },
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );

      setState(prev => ({
        ...prev,
        showForm: false,
        errors: {},
        taskToAddValue: '',
        isLoading: false
      }));

      // Fetch updated tasks
      await fetchTasks();

    } catch (error) {
      console.error('Create error:', error);
      setState(prev => ({
        ...prev,
        isLoading: false,
        errors: error.response?.data || { general: 'Failed to create task' }
      }));
    }
  };

  const saveRowData = async () => {
    setState(prev => ({ ...prev, isLoading: true }));

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/task/`,
        {
          _id: state.editTask._id,
          taskName: state.taskToAddValue,
        },
        {
          headers: { 'Authorization': localStorage.jwtToken }
        }
      );

      // Reset form state
      setState(prev => ({
        ...prev,
        editTask: null,
        showForm: false,
        errors: {},
        taskToAddValue: '',
        isLoading: false
      }));

      // Fetch updated tasks
      await fetchTasks();

    } catch (error) {
      console.error('Edit error:', error);
      setState(prev => ({
        ...prev,
        isLoading: false,
        errors: error.response?.data || { general: 'Failed to edit task' }
      }));
    }
  };

  return (
    <div className="content">
      <Dock
        position='right'
        isVisible={state.showForm}
        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
        duration={300}
      >
        {state.showForm && (
          <Row>
            <Col>
              <CardBody>
                <h3>
                  {t(`${state.editTask ? 'Edit' : 'Add'} Task`)}
                </h3>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': state.errors.taskName
                      })}
                      value={state.taskToAddValue}
                      onChange={(e) => setState(prev => ({
                        ...prev,
                        taskToAddValue: e.target.value
                      }))}
                      placeholder={t('Add task name')}
                    />
                    {state.errors.taskName && (
                      <div className="invalid-feedback d-block">
                        {state.errors.taskName}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="info"
                      onClick={() => createNewTask(state.taskToAddValue)}
                      disabled={state.isLoading}
                    >
                      {state.isLoading ? (
                        <span>
                          <i className="fa fa-spinner fa-spin" /> {t('Loading...')}
                        </span>
                      ) : (
                        t(`${state.editTask ? 'Save' : 'Add'} Task`)
                      )}
                    </Button>
                    <Button 
                      color="info"
                      onClick={() => setState(prev => ({ ...prev, showForm: false }))}
                    >
                      {t("Cancel")}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        )}
      </Dock>

      {userCan(user, 'can_manage_tasks_and_materials') && (
        <Button 
          color="info" 
          onClick={() => setState(prev => ({ ...prev, showForm: !prev.showForm }))}
        >
          {t("Add Task")}
        </Button>
      )}

      <ReactTable
        data={state.tasks}
        columns={[
          {
            Header: t("Task Id"),
            accessor: "_id",
            show: false
          },
          {
            Header: t("Task"),
            accessor: "taskName",
            Cell: renderEditable
          },
          {
            Header: t("Actions"),
            id: "activateToggle",
            width: 150,
            Cell: (value) => renderActiveButtons(value)
          }
        ]}
        defaultPageSize={1}
        pageSize={state.tasks?.length}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight primary-pagination"
        noDataText={t('No tasks found')}
        loading={state.isLoading}
      />

      {state.errors?.general && (
        <div className="text-danger text-center mt-3">
          {state.errors?.general}
        </div>
      )}
    </div>
  );
};

ConnectedAddTaskTable.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object,
  projectId: PropTypes.string,
  taskData: PropTypes.array,
  getSomeTasks: PropTypes.func,
  refreshTask: PropTypes.func
};

ConnectedAddTaskTable.defaultProps = {
  taskData: [],
  getSomeTasks: () => {},
  refreshTask: () => {}
};

const AddTaskTable = withTranslation()(
  connect(mapStateToProps)(ConnectedAddTaskTable)
);

export default AddTaskTable;

import React from "react";
import _ from "lodash"
import axios from "axios"
import moment from "moment"
import ContractedWorksFormByEmployee from "./ContractedWorksFormByEmployee"
import UpdateContractedWorksFormByEmployee from "./UpdateContractedWorksFormByEmployee"
import {connect} from "react-redux";
import {getProjects} from "../../../actions/index";
import {Dock} from "react-dock"
import contractedWorkhelper from '../../../utils/contractedWork_helper.js'
import {setScheduleItem} from '../../../actions/scheduleActions'
import {withTranslation} from 'react-i18next';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Button
} from "reactstrap";
import ContractorWorkTable from "./ContractorWorkTable";
import {userCant, userCan} from "../../../services/Authorization/authService";


function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProjects: () => dispatch(getProjects()),
        setScheduleItem: scheduleItem => dispatch(setScheduleItem(scheduleItem))
    };
}


class ConnectedContractedWork extends React.Component {
    constructor(props) {
        super(props);
        this.getContractorWorkTable = this.getContractorWorkTable.bind(this)
        this.getContractorSummaryByDate = this.getContractorSummaryByDate.bind(this)
        this.getSomeMaterials = this.getSomeMaterials.bind(this)
        this.getSomeTasks = this.getSomeTasks.bind(this)
        this.getSomeSiteLocations = this.getSomeSiteLocations.bind(this)
        this.getSomeProjectstructures = this.getSomeProjectstructures.bind(this)
        this.isDockVisibleControl = this.isDockVisibleControl.bind(this)
        this.isUpdateDockVisibleControl = this.isUpdateDockVisibleControl.bind(this)
        this.setShouldUpdateWorkingHours = this.setShouldUpdateWorkingHours.bind(this)
        this.setShowUpdateWorkingHoursToFalse = this.setShowUpdateWorkingHoursToFalse.bind(this)
        this.refreshMaterial = this.refreshMaterial.bind(this)
        this.refreshTask = this.refreshTask.bind(this)
        this.state = {
            dynamicTreeData: [],
            datafetch: [],
            rowsToCopy: [],
            selected: -1,
            employees: [],
            dateList: [],
            today: moment(new Date()),
            dateSelected: '',
            selectedRowList: [],
            resetState: false,
            scheduleClickedItem: '',
            contractorSummaryTableData: [],
            materialData: [],
            taskData: [],
            siteLocationData: [],
            hoursWorked: [],
            contractorWorkTableData: [],
            additionalWorkTableData: [],
            project: "",
            projectId: "",
            projectStructureTableData: [],
            formattedTableStructure: [],
            isDockVisible: false,
            isUpdateDockVisible: false,
            contractedWorkRowData: [],
            shouldUpdateWorkingHours: false
        };

        this.getContractorWorkTable()

        this.getContractorSummaryByDate()
        this.getSomeProjectstructures()
    }

    componentWillMount() {
        if (
            userCant(this.props.user, 'contractwork.index') &&
            userCant(this.props.user, 'contractwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {

        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        var endDate = new Date();
        endDate.setDate(endDate.getDate());
        var getDateArray = function (start, end) {
            var arr = [];
            var dt = new Date(start);
            while (dt <= end) {
                arr.push(new Date(dt));
                dt.setDate(dt.getDate() + 1);
            }

            return arr;


        }

        var dateArr = getDateArray(startDate, endDate);
        this.setState({dateList: dateArr}, () => this.state.dateList)
        // Refactor and move to a helper API

        this.getSomeMaterials();
        this.getSomeTasks();
        this.getSomeSiteLocations();
        var hoursWorkedInDay = this.getTimeStops('00:00', '23:59', 30)
        this.setState({hoursWorked: hoursWorkedInDay}, () => console.log(this.state.hoursWorked))
        // this.getContractorWorkTableByDate();

    }


    componentDidUpdate(prevProps) {

        if (this.props.projectId !== prevProps.projectId) {
            this.getContractorWorkTable();
            // //this.getAdditionalWorkTable();
            this.getContractorSummaryByDate();
            this.getSomeTasks();
            // this.getProjectSchedule();
            this.getSomeProjectstructures();
        } else if (_.isEmpty(this.props.projectData[0])) {
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getContractorWorkTable();
            // // this.getAdditionalWorkTable();
            this.getContractorSummaryByDate();
            this.getSomeTasks();
            this.getSomeProjectstructures()
        }

    }

    getSomeMaterials() {
        if (userCant(this.props.user, 'contractwork.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/material/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    materialData: res.data
                }, () => this.state.materialData);
                return res.data
            }).catch((error) => {
            ;
        })
    }

    getSomeTasks() {
        if (userCant(this.props.user, 'contractwork.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/task/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    taskData: res.data
                }, () => console.log(this.state.taskData));
                return res.data
            }).catch((error) => {
            ;
        })
    }

    getSomeSiteLocations() {

        if (userCant(this.props.user, 'contractwork.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/sitelocation/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    siteLocationData: res.data
                }, () => this.state.siteLocationData);
                return res.data
            }).catch((error) => {
            ;
        })
    }

    async getProjectSchedule() {
        if (userCant(this.props.user, 'contractwork.manage')) {
            return
        }

        const treeData = await axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .catch((error) => {
                console.log(error);
            })

        this.setState({dynamicTreeData: treeData.data}, () => {
        })

    }

    getTimeStops(start, end, step) {
        var startTime = moment(start, 'HH:mm');
        var endTime = moment(end, 'HH:mm');

        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }

        var timeStops = [];

        while (startTime <= endTime) {
            timeStops.push(new moment(startTime));
            startTime.add(step, 'minutes');
        }
        return timeStops;
    }

    async getContractorWorkTable() {

        if (
            userCant(this.props.user, 'contractwork.index') &&
            userCant(this.props.user, 'contractwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }


        var formattedData = []
        const contractorWorkTableResponse = await axios.get(process.env.REACT_APP_API_URL + "/api/contractorwork/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .catch((error) => {
                console.log(error)
            })

        this.setState({
            contractorWorkTableData: contractorWorkTableResponse?.data
        }, () => {
            console.log(this.state.contractorWorkTableData)
            formattedData = contractedWorkhelper.formatContractedWorkDataForTables(this.state.contractorWorkTableData)

            const tableStructure = formattedData.map(x => {
                    return {
                        '_id': x[2],
                        'projectId': x[3],
                        'scheduledItem': x[5],
                        'contractedWorkDetail': x[0],
                        'timesheetLogDetail': x[6],
                        'duration': x[1]
                    }
                }
            )


            this.setState({
                formattedTableStructure: tableStructure
            }, () => {
            });
            return contractorWorkTableResponse?.data
        })
    }


    getContractorSummaryByDate() {

        if (
            userCant(this.props.user, 'contractwork.index') &&
            userCant(this.props.user, 'contractwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }

        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/summary/companiesintimesheetlogs/' + this.props.reportDate + '/projects/' + this.props.projectId,
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({contractorSummaryTableData: res.data}, () => {
                })
                return res.data
            })
            .catch((error) => {

                console.log(error);
            })
    }

    getSomeProjectstructures() {

        if (userCant(this.props.user, 'contractwork.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/projectstructure/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                if (Array.isArray(res.data)) {
                    this.setState({

                        projectStructureTableData: res.data
                    }, () => {
                        console.log(res.data)
                    });
                }
                return []
            }).catch((error) => {
            ;
        })
    }

    isDockVisibleControl() {
        this.setState({isDockVisible: !this.state.isDockVisible}, () => {
        })
    }

    isUpdateDockVisibleControl(row_id, tableObject) {
        let _contractedWorkRowData = [];

        if (tableObject !== undefined) {
            _contractedWorkRowData = tableObject.filter(obj => `${obj._id}` === `${row_id}`)
        }

        this.setState({
            contractedWorkRowData: _contractedWorkRowData,
            isUpdateDockVisible: !this.state.isUpdateDockVisible
        })
    }

    setShouldUpdateWorkingHours() {
        this.setState({shouldUpdateWorkingHours: true}, () => {
        })
    }

    setShowUpdateWorkingHoursToFalse() {
        this.setState({shouldUpdateWorkingHours: false}, () => {
        })
    }

    refreshMaterial(material) {
        let materialData = this.state.materialData.map(row => {
            return row._id === material._id ? material : row
        })

        this.setState({
            materialData: materialData
        })
    }

    refreshTask(task) {
        let tasksData = this.state.taskData.map(row => {
            return row._id === task._id ? task : row
        })

        this.setState({
            taskData: tasksData
        })
    }

    render() {

        if (
            userCant(this.props.user, 'contractwork.index') &&
            userCant(this.props.user, 'contractwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12" tag="h3">{this.props.t("Contracted Work")}</Col>
                    </Row>
                    {userCan(this.props.user, 'contractwork.manage') && <Row>
                        <Col>
                            <FormGroup>
                                <Button color="info" type="submit" onClick={() => {
                                    this.isDockVisibleControl()
                                }}>
                                    {this.props.t("Add Contracted Work")}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle
                                        tag="h4">{this.props.t("Contract Work Logged for Project")}: {this.props.project}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ContractorWorkTable
                                        isUpdateDockVisibleControl={this.isUpdateDockVisibleControl}
                                        formattedTableStructure={this.state.formattedTableStructure}
                                        contractorWorkTableData={this.state.contractorWorkTableData}
                                        getContractorWorkTable={this.getContractorWorkTable}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {this.state.isDockVisible && <Dock
                        position='right'
                        isVisible={this.state.isDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : 0.865}
                        zIndex={99999}
                    >
                        <Card>
                            <CardHeader>
                                <CardTitle
                                    tag="h4">
                                    <Button color="info" className={'mr-2'} onClick={() => {
                                        this.isDockVisibleControl()
                                        this.setShowUpdateWorkingHoursToFalse()
                                    }}>
                                        {this.props.t("Close")}
                                    </Button>
                                    {this.props.t("Add Contract Work")}
                                </CardTitle>
                                <label>({this.props.t("Time and Attendance must be completed first")})</label>
                            </CardHeader>
                            <CardBody>
                                <ContractedWorksFormByEmployee
                                    isDockVisible={this.state.isDockVisible}
                                    setShowUpdateWorkingHoursToFalse={this.setShowUpdateWorkingHoursToFalse}
                                    setShouldUpdateWorkingHours={this.setShouldUpdateWorkingHours}
                                    shouldUpdateWorkingHours={this.state.shouldUpdateWorkingHours}
                                    contractedWorkRowData={this.state.contractedWorkRowData}
                                    isDockVisibleControl={this.isDockVisibleControl}
                                    getAdditionalWorkTable={this.getAdditionalWorkTable}
                                    getContractorWorkTable={this.getContractorWorkTable}
                                    scheduleClickedItem={this.state.scheduleClickedItem}
                                    contractorSummaryTableData={this.state.contractorSummaryTableData}
                                    materialTableData={this.state.materialData}
                                    taskTableData={this.state.taskData}
                                    siteLocationTableData={this.state.siteLocationData}
                                    hoursWorked={this.state.hoursWorked}
                                    projectStructureTableData={this.state.projectStructureTableData}
                                />
                            </CardBody>
                        </Card>
                    </Dock>
                    }
                    {this.state.isUpdateDockVisible && <Dock
                        position='right'
                        isVisible={this.state.isUpdateDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : 0.865}
                        zIndex={99999}
                    >
                        <Card>
                            <CardHeader>
                                <CardTitle
                                    tag="h4">
                                    <Button className={'mr-2'} color="info" onClick={() => {
                                        this.isUpdateDockVisibleControl()
                                    }}>
                                        {this.props.t("Close")}
                                    </Button>
                                    {this.props.t("Update Contract Work")}
                                </CardTitle><label>({this.props.t("Time and Attendance must be completed first")})</label>
                            </CardHeader>
                            <CardBody>
                                <UpdateContractedWorksFormByEmployee
                                    isDockVisible={this.state.isUpdateDockVisible}
                                    setShouldUpdateWorkingHours={this.setShouldUpdateWorkingHours}
                                    isUpdateDockVisibleControl={this.isUpdateDockVisibleControl}
                                    contractedWorkRowData={this.state.contractedWorkRowData}
                                    getContractorWorkTable={this.getContractorWorkTable}
                                    scheduleClickedItem={this.state.scheduleClickedItem}
                                    contractorSummaryTableData={this.state.contractorSummaryTableData}
                                    materialTableData={this.state.materialData}
                                    taskTableData={this.state.taskData}
                                    siteLocationTableData={this.state.siteLocationData}
                                    hoursWorked={this.state.hoursWorked}
                                    projectStructureTableData={this.state.projectStructureTableData}
                                    formattedTableStructure={this.state.formattedTableStructure}
                                />
                            </CardBody>
                        </Card>
                    </Dock>}
                </div>
            </>
        );
    }
}

const ContractedWork = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedContractedWork));

export default ContractedWork;

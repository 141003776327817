import React from "react";
import axios from "axios"
import moment from "moment"
import {connect} from "react-redux";
import ReactTable from "react-table";
import {CSVLink} from "react-csv"
import _ from 'lodash'
import Select from "react-select"
import Datetime from 'react-datetime';
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import {userCant} from "../../../services/Authorization/authService";

import {withTranslation} from 'react-i18next';
import {WorkFilters} from "../Work/WorkFilters";
import helper from "../../../utils/general_helper";
import ReactBSAlert from "react-bootstrap-sweetalert";


function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user
    };
}

class ConnectedTimesheetLogDetailReport extends React.Component {
    constructor(props) {
        super(props);


        this.getTimesheetLogByDate = this.getTimesheetLogByDate.bind(this)
        this.dateSelectedFromDropdownIntervals = this.dateSelectedFromDropdownIntervals.bind(this)
        this.getEmployeesCompressedListByDate = this.getEmployeesCompressedListByDate.bind(this)
        this.download = this.download.bind(this);
        this.generateTimesheetsLogDetail = this.generateTimesheetsLogDetail.bind(this)
        this.generateAdditionalReportDetail = this.generateAdditionalReportDetail.bind(this)
        this.generateContractedWorkReportDetail = this.generateContractedWorkReportDetail.bind(this)
        this.duplicateItems = this.duplicateItems.bind(this)
        this.getScheduleItemFullPath = this.getScheduleItemFullPath.bind(this)
        this.Report = this.Report.bind(this)
        this.getReport = this.getReport.bind(this)
        this.valid = this.valid.bind(this)
        this.generateDelaysReportDetail = this.generateDelaysReportDetail.bind(this)
        this.getSomeProjectstructures = this.getSomeProjectstructures.bind(this)
        this.getSomeTasks = this.getSomeTasks.bind(this)
        this.getReportFiltersDateLimits = this.getReportFiltersDateLimits.bind(this)
        this.getSomeMaterials = this.getSomeMaterials.bind(this)
        this.getSomeInstructions = this.getSomeInstructions.bind(this)
        this.getSomeUsers = this.getSomeUsers.bind(this)
        this.getSomeCompanies = this.getSomeCompanies.bind(this)

        this.state = {
            scheduleItemId: "61e3f3f9c55a4f1d6b730f10",
            timesheetLogDetailReportData: [],
            timesheetReportData: [],
            dataToDownload: [],
            contractedWorkReportData: [],
            additionalWorkReportData: [],
            additionalWorkReportColumns: [],
            initWorkFilters: false,
            delaysReportData: [],
            totalHours: '-',
            additionalWorkColumns: [
                {
                    Header: "Id",
                    accessor: "_id",
                    show: false
                },
                {
                    Header: this.props.t("Project Id"),
                    accessor: "projectId",
                    show: false
                },
                {
                    Header: this.props.t("Date of Work"),
                    accessor: "date",
                    Cell: (props) => {
                        //props.value will contain your date
                        //you can convert your date here
                        const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                        return <span>{custom_date}</span>
                    }
                },
                {
                    Header: this.props.t("Company"),
                    name: 'company',
                    Cell: ({original}) => {
                        let user = original.timesheetLogDetail?.user_id

                        if (!user) {
                            return '-'
                        }


                        if (!user.companies) {
                            return ''
                        }

                        let companies = [];

                        user.companies.forEach(company => {
                            company.locations = user.locations.filter(l => l.companyId == company._id)
                            companies.push(company);
                        })


                        return companies.map(company => company.name).join(', ');
                    }
                },
                {
                    Header: this.props.t("Scheduled Item"),
                    accessor: "scheduledItemId.full_path"
                },
                {
                    Header: this.props.t("Instruction Number"),
                    accessor: "instruction.userDefinedInstructionNumber"
                },
                {
                    Header: this.props.t("Instruction Title"),
                    accessor: "instruction.instructionTitle"
                },
                {
                    Header: this.props.t("Building"),
                    accessor: "building.building"
                },
                {
                    Header: this.props.t("Level"),
                    accessor: "level.name"
                },
                {
                    Header: this.props.t("Elevation"),
                    accessor: "elevation.name"
                },
                {
                    Header: this.props.t("System"),
                    accessor: "system.name"
                },
                {
                    Header: this.props.t("Task"),
                    accessor: "task.taskName"
                },
                {
                    Header: this.props.t("Material"),
                    accessor: "material.materialName"
                },
                {
                    Header: this.props.t("Comments"),
                    accessor: "comment",
                },
                {
                    Header: this.props.t("Person"),
                    id: "timesheetLogDetail",
                    accessor: d => d.timesheetLogDetail?.user_id ? `${d.timesheetLogDetail.user_id?.name} ${d.timesheetLogDetail.user_id?.last_name}` : '-',
                },
                {
                    Header: this.props.t('Allocated Time'),
                    name: 'allocated_time',
                    Cell: ({original}) => {
                        let hours = Number(original.timesheetLogDetail?.allocatedHours ?? 0);

                        if (hours) {
                            hours *= 60;
                        }

                        return this.convertMinutesToHoursMinutes(
                            hours +
                            Number(original.timesheetLogDetail?.allocatedMinutes ?? 0),
                        )
                    }
                },
                // {
                //     Header: this.props.t("Allocated Hours"),
                //     id: "timesheetLogDetailHours",
                //     accessor: d => `${d.timesheetLogDetail?.allocatedHours || '-'}`,
                // },
                // {
                //     Header: this.props.t("Allocated Minutes"),
                //     id: "timesheetLogDetailMinutes",
                //     accessor: d => `${d.timesheetLogDetail?.allocatedMinutes || '-'}`,
                // },
                {
                    Header: this.props.t("Position"),
                    id: "timesheetLogDetailPosition",
                    accessor: d => `${d.timesheetLogDetail.user_id?.positions[0]?.name || '-'}`,

                },
            ],
            contractedWorkColumns: [
                {
                    Header: "Id",
                    accessor: "_id",
                    show: false
                },
                {
                    Header: this.props.t("Project Id"),
                    accessor: "projectId",
                    show: false
                },
                {
                    Header: this.props.t("Date of Work"),
                    accessor: "date",
                    Cell: (props) => {
                        //props.value will contain your date
                        //you can convert your date here
                        const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                        return <span>{custom_date}</span>
                    }
                },
                {
                    Header: this.props.t("Company"),
                    name: 'company',
                    Cell: ({original}) => {
                        let user = original.timesheetLogDetail?.user_id

                        if (!user) {
                            return '-'
                        }


                        if (!user.companies) {
                            return ''
                        }

                        let companies = [];

                        user.companies.forEach(company => {
                            company.locations = user.locations.filter(l => l.companyId == company._id)
                            companies.push(company);
                        })


                        return companies.map(company => company.name).join(', ');
                    }
                },
                {
                    Header: this.props.t("Scheduled Item"),
                    accessor: "scheduledItemId.full_path"
                },
                {
                    Header: this.props.t("Building"),
                    accessor: "building.building"
                },
                {
                    Header: this.props.t("Level"),
                    accessor: "level.name"
                },
                {
                    Header: this.props.t("Elevation"),
                    accessor: "elevation.name"
                },
                {
                    Header: this.props.t("System"),
                    accessor: "system.name"
                },
                {
                    Header: this.props.t("Task"),
                    accessor: "task.taskName"
                },
                {
                    Header: this.props.t("Material"),
                    accessor: "material.materialName"
                },
                {
                    Header: this.props.t("Comments"),
                    accessor: "comment",
                },
                {
                    Header: this.props.t("Person"),
                    id: "timesheetLogDetail",
                    accessor: d => d.timesheetLogDetail?.user_id ? `${d.timesheetLogDetail?.user_id?.name} ${d.timesheetLogDetail?.user_id?.last_name}` : '-',
                },
                {
                    Header: this.props.t('Allocated Time'),
                    name: 'allocated_time',
                    Cell: ({original}) => {
                        let hours = Number(original.timesheetLogDetail?.allocatedHours ?? 0);

                        if (hours) {
                            hours *= 60;
                        }

                        return this.convertMinutesToHoursMinutes(
                            hours +
                            Number(original.timesheetLogDetail?.allocatedMinutes ?? 0),
                        )
                    }
                },
                // {
                //     Header: this.props.t("Allocated Hours"),
                //     id: "timesheetLogDetailHours",
                //     accessor: d => `${d.timesheetLogDetail?.allocatedHours || '-'}`,
                // },
                // {
                //     Header: this.props.t("Allocated Minutes"),
                //     id: "timesheetLogDetailMinutes",
                //     accessor: d => `${d.timesheetLogDetail?.allocatedMinutes || '-'}`,
                // },
                {
                    Header: this.props.t("Position"),
                    id: "timesheetLogDetailPosition",
                    accessor: d => `${d.timesheetLogDetail?.user_id?.positions[0]?.name || '-'}`,

                },
            ],
            delaysColumns: [
                {
                    Header: this.props.t("Delay"),
                    accessor: "_id",
                    show: false
                },
                {
                    Header: this.props.t("Delay Date"),
                    accessor: "delayDate",
                    Cell: (props) => {
                        //props.value will contain your date
                        //you can convert your date here
                        const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                        return <span>{custom_date}</span>
                    }
                },
                {
                    Header: this.props.t("Delay Type"),
                    accessor: "delayTypeDescription",
                }, {
                    Header: this.props.t("Delay Type Activity"),
                    accessor: "delayTypeActivity",
                },

                {
                    Header: this.props.t("Comments"),
                    accessor: "comment",
                },
            ],
            reportType: '',
            reportTypeSelect: [
                {value: 'contractedwork', label: this.props.t('Contracted Work')},
                {value: 'additionalwork', label: this.props.t('Additional Work')},
                {value: 'delays', label: this.props.t('Delays')},
                {value: 'rentals', label: this.props.t('Rentals')}
            ],
            fromDate: '',
            toDate: '',
            projectStructureTableData: [],
            taskData: [],
            materialData: [],
            instructionData: [],
            userTableData: [],
            companyTableData: [],
            alert: null,
            dateLimits: {},
            rentalsReportData: [],
            rentalsColumns: [
                {
                    Header: this.props.t("Rental ID"),
                    accessor: "_id",
                    show: false
                },
                {
                    Header: this.props.t("Company Renting"),
                    accessor: "companyRenting"
                },
                {
                    Header: this.props.t("Equipment Type/Model"),
                    accessor: "typeModel"
                },
                {
                    Header: this.props.t("Description"),
                    accessor: "description"
                },
                {
                    Header: this.props.t("Rental Company"),
                    accessor: "rentalCompany.name" // Assuming it's populated from ref
                },
                {
                    Header: this.props.t("Start Date"),
                    accessor: "rentalDate",
                    Cell: (props) => {
                        const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                        return <span>{custom_date}</span>
                    }
                },
                {
                    Header: this.props.t("End Date"),
                    accessor: "rentalFinishedDate",
                    Cell: (props) => {
                        const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                        return <span>{custom_date}</span>
                    }
                },
                {
                    Header: this.props.t("Duration (Days)"),
                    accessor: "numberOfDays"
                },
                // {
                //     Header: this.props.t("Status"),
                //     accessor: "rentalIsFinished",
                //     Cell: ({value}) => (
                //         <span>{value ? 'Finished' : 'Active'}</span>
                //     )
                // },
                {
                    Header: this.props.t("Comments"),
                    accessor: "comment"
                },
                {
                    Header: this.props.t("Created Date"),
                    accessor: "createdDate",
                    Cell: (props) => {
                        const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                        return <span>{custom_date}</span>
                    }
                }
            ],
            loadingProgress: {
                dateLimits: false,
                structures: false,
                tasks: false,
                materials: false,
                instructions: false,
                users: false,
                companies: false
            }
        };
    }

    // Add new method for sequential loading
    initializeFilters = async () => {
        try {
            await this.getReportFiltersDateLimits();
            await this.getSomeProjectstructures();
            await this.getSomeTasks();
            await this.getSomeMaterials();
            await this.getSomeInstructions();
            await this.getSomeUsers();
            await this.getSomeCompanies();
        } catch (error) {
            console.error('Error initializing filters:', error);
        }
    }

    componentDidMount() {
        this.getReportFiltersDateLimits();
        this.batchLoadData();
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        var endDate = new Date();
        endDate.setDate(endDate.getDate());
        var getDateArray = function (start, end) {
            var arr = new Array();
            var dt = new Date(start);
            while (dt <= end) {
                arr.push(new Date(dt));
                dt.setDate(dt.getDate() + 1);
            }
            return arr;
        }

        var dateArr = getDateArray(startDate, endDate);
        this.setState({dateList: dateArr}, () => this.state.dateList)
        // Refactor and move to a helper API
        this.getTimesheetLogByDate(this.props.reportDate)
        this.getEmployeesCompressedListByDate(this.props.reportDate)
    }

    componentDidUpdate(prevProps, prevState) {


        if (this.props.projectId !== prevProps.projectId) {

            var setDateToday = moment().format("YYYY-MM-DD")
            this.getTimesheetLogByDate(this.props.reportDate)
            this.getSomeProjectstructures();
            this.getSomeTasks();
            this.getSomeMaterials();
            this.getSomeInstructions();
            this.getSomeUsers();
            this.getSomeCompanies();
            this.getReportFiltersDateLimits()
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getTimesheetLogByDate(this.props.reportDate)
        }

        if (this.state.reportType !== prevState.reportType) {
            // if (this.state.reportType === 'additionalwork') {
            //   this.generateAdditionalReportDetail()
            // }
            // if (this.state.reportType === 'contractedwork') {
            //   this.generateContractedWorkReportDetail()
            // }
        }

    }

    getReportFiltersDateLimits() {
        this.setState({ loadingProgress: { ...this.state.loadingProgress, dateLimits: true }});
        let url = `${process.env.REACT_APP_API_URL}/api/general-report/project/${this.props.projectId}/get-filters-dates-limit`;
        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(response => {
            this.setState({
                dateLimits: response.data
            })
        })
    }

    convertMinutesToHoursMinutes(num) {
        let hours = (num / 60),
            rhours = Math.floor(hours),
            minutes = (hours - rhours) * 60,
            rminutes = Math.round(minutes);

        return rhours + '.' + rminutes;
    }

    componentWillMount() {
        if (userCant(this.props.user, 'generalReport.manage')) {
            this.props.history.push('/admin/instructions')
        }
    }

    getSomeInstructions() {

        axios.get(process.env.REACT_APP_API_URL + "/api/instruction/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                instructionData: res.data
            });

            return res.data
        })
    }

    getSomeProjectstructures() {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            axios.get(process.env.REACT_APP_API_URL + "/api/projectstructure/project/" + this.props.projectId, {
                headers: {
                    'Authorization': localStorage.jwtToken
                }
            }).then(res => {
                if (Array.isArray(res.data)) {
                    this.setState({
                        projectStructureTableData: res.data
                    }, resolve);
                } else {
                    resolve();
                }
            }).catch(resolve); // Still resolve on error to continue chain
        });
    }

    getSomeMaterials() {

        axios.get(process.env.REACT_APP_API_URL + "/api/material/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                materialData: res.data
            }, () => this.state.materialData);
            return res.data
        })
    }

    getSomeTasks() {
        axios.get(process.env.REACT_APP_API_URL + "/api/task/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                taskData: res.data
            });
            return res.data
        })
    }

    getTimesheetLogByDate(dateToSearch) {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + dateToSearch + "/projects/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                timesheetdatafetch: res.data
            }, () => {
            });

            return res.data
        })
            .catch((error) => {
                ;
            })
    }

    getEmployeesCompressedListByDate(dateToSearch) {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/employeescompressedlist/' + dateToSearch + '/projects/' + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                datafetch: res.data
            }, () => {
            });

            return res.data
        })
            .catch((error) => {
                ;
            })
    }


    dateSelectedFromDropdownIntervals(dateIn) {
        this.setState({dateSelected: dateIn}, () => this.state.dateSelected)
    }

    selectedRowState(resetValue) {
        this.setState({resetState: resetValue}, () => this.state.resetState)

    }

    getScheduleItemFullPath(scheduleId) {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/plan/project/" + this.props.projectId + "/itemfullpath/" + this.state.scheduleItemId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(
            res => {

                this.setState({
                    fullpath: res.data
                }, () => {
                    console.log(res.data)
                })
            })
    }

    ////// EXPERIMENTAL TIMESHEET REPORT ////////////
    generateTimesheetsLogDetail(start, end) {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/reports/timesheetlogdetail?reportdate=" + this.props.reportDate + "&projectid=" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(
            res => {
                // var x = []
                // x.push(res.data);

                this.setState({
                    timesheetLogDetailReportData: res.data
                }, () => {
                    console.log(this.state.timesheetLogDetailReportData)
                })
            })
    }


    generateAdditionalReportDetail(filtersQueryString = '') {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }

        this.setState({
            alert: <ReactBSAlert
                style={{display: "block", marginTop: "-100px"}}
                title={this.props.t("Report Generation")}
                onConfirm={() => {
                }}
                showConfirm={false}
            >
                Please wait - data are being prepared
            </ReactBSAlert>
        })

        let url = process.env.REACT_APP_API_URL + "/api/reports/additionalwork";

        if (filtersQueryString) {
            url += '?' + filtersQueryString
        }

        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                additionalWorkReportData: res.data,
                reportType: 'additionalwork'
            }, () => {
                var rowsForAdditionaWork = []
                let totalAllocatedMinutes = 0;

                if (this.state.additionalWorkReportData.length > 0) {
                    this.state.additionalWorkReportData.forEach((value, index) => {

                            if (value.timesheetLogDetail.length > 0) {
                                for (let i = 0; i < value.timesheetLogDetail.length; ++i) {
                                    totalAllocatedMinutes += value.timesheetLogDetail[i].allocated;

                                    var deepCopyRowObject = _.cloneDeep(value)
                                    deepCopyRowObject.timesheetLogDetail = value.timesheetLogDetail[i]
                                    rowsForAdditionaWork.push(deepCopyRowObject)
                                }
                            } else {
                                rowsForAdditionaWork.push(
                                    value
                                )
                            }

                        }
                    )
                }

                this.setState({
                    additionalWorkReportData: rowsForAdditionaWork,
                    totalHours: helper.hoursMinutesAllocateTimeFormatted(totalAllocatedMinutes),
                    alert: null
                }, () => {
                })
            })
        })

    }

    generateContractedWorkReportDetail(filtersQueryString = '') {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }

        this.setState({
            alert: <ReactBSAlert
                style={{display: "block", marginTop: "-100px"}}
                title={this.props.t("Report Generation")}
                onConfirm={() => {
                }}
                showConfirm={false}
            >
                Please wait - data are being prepared
            </ReactBSAlert>
        })

        let url = process.env.REACT_APP_API_URL + "/api/reports/contractedwork";

        if (filtersQueryString) {
            url += '?' + filtersQueryString
        }

        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {

            this.setState({
                contractedWorkReportData: res.data,
                reportType: 'contractedwork'
            }, () => {
                var rowsForContractedWork = []

                let totalAllocatedMinutes = 0;

                if (this.state.contractedWorkReportData.length > 0) {
                    this.state.contractedWorkReportData.forEach((value, index) => {

                            if (value.timesheetLogDetail.length > 0) {
                                for (let i = 0; i < value.timesheetLogDetail.length; ++i) {
                                    totalAllocatedMinutes += value.timesheetLogDetail[i].allocated;
                                    var deepCopyRowObject = _.cloneDeep(value)
                                    deepCopyRowObject.timesheetLogDetail = value.timesheetLogDetail[i]
                                    rowsForContractedWork.push(deepCopyRowObject)
                                }
                            } else {
                                rowsForContractedWork.push(
                                    value
                                )
                            }

                        }
                    )
                }

                this.setState({
                    contractedWorkReportData: rowsForContractedWork,
                    totalHours: helper.hoursMinutesAllocateTimeFormatted(totalAllocatedMinutes),
                    alert: null
                }, () => {
                })
            })
        })

    }


    generateDelaysReportDetail(filtersQueryString = '') {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }

        this.setState({
            alert: <ReactBSAlert
                style={{display: "block", marginTop: "-100px"}}
                title={this.props.t("Report Generation")}
                onConfirm={() => {
                }}
                showConfirm={false}
            >
                Please wait - data are being prepared
            </ReactBSAlert>
        })

        let url = process.env.REACT_APP_API_URL + "/api/reports/delays";

        if (filtersQueryString) {
            url += '?' + filtersQueryString
        }

        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                delaysReportData: res.data,
                reportType: 'delays',
                alert: null
            })
        })
    }

    generateRentalsReportDetail(filtersQueryString = '') {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }

        this.setState({
            alert: <ReactBSAlert
                style={{display: "block", marginTop: "-100px"}}
                title={this.props.t("Report Generation")}
                onConfirm={() => {}}
                showConfirm={false}
            >
                {this.props.t("Please wait - data are being prepared")}
            </ReactBSAlert>
        })

        let url = `${process.env.REACT_APP_API_URL}/api/reports/rentals`;

        // Add project ID to query string if not present
        const params = new URLSearchParams(filtersQueryString);
        if (!params.has('projectId')) {
            params.append('projectId', this.props.projectId);
        }

        // Add date filters if present
        if (params.has('from') || params.has('to')) {
            url += '?' + params.toString();
        }

        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            // Calculate total rental days if needed
            const rentalsData = res.data.map(rental => ({
                ...rental,
                // Add any computed fields here if needed
                numberOfDays: rental.numberOfDays || 
                    moment(rental.rentalFinishedDate).diff(moment(rental.rentalDate), 'days')
            }));

            this.setState({
                rentalsReportData: rentalsData,
                reportType: 'rentals',
                alert: null,
                // Add any additional state updates needed
                dataToDownload: rentalsData // For CSV export
            });
        }).catch(error => {
            console.error('Error fetching rentals report:', error);
            this.setState({
                alert: <ReactBSAlert
                    style={{display: "block", marginTop: "-100px"}}
                    title={this.props.t("Error")}
                    onConfirm={() => this.setState({alert: null})}
                    showConfirm={true}
                >
                    {this.props.t("Error generating rentals report")}
                </ReactBSAlert>
            });
        });
    }

    duplicateItems(array, times) {
        console.log(array)
        return array.reduce((res, current) => {
            return res.concat(Array(times).fill(current));
        }, []);
    }

    generateTimesheetsOverview(start, end) {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/reports/timesheets/overview?start=" + start + "&end=" + end, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(
            res => {
                this.setState({
                    timesheetReportData: res.data
                }, () => {

                    console.log(this.state.timesheetReportData)
                })
                console.log(res.data)
            })
    }


    download = async () => {
        // Get the correct data based on report type
        let dataSource;
        switch (this.state.reportType) {
            case 'contractedwork':
                dataSource = this.state.contractedWorkReportData;
                break;
            case 'additionalwork':
                dataSource = this.state.additionalWorkReportData;
                break;
            case 'delays':
                dataSource = this.state.delaysReportData;
                break;
            case 'rentals':
                dataSource = this.state.rentalsReportData;
                break;
            default:
                dataSource = [];
        }

        const columns = this.getColumnsForCurrentReport();
        
        const data_to_download = dataSource.map(record => {
            let record_to_download = {};

            columns.forEach(column => {
                if (column.show !== false) {
                    let value = '';

                    // Handle specific known fields
                    switch(column.Header) {
                        case this.props.t('Company'):
                            // Match the exact logic from the table Cell renderer
                            const user = record.timesheetLogDetail?.user_id;
                            if (!user || !user.companies) {
                                value = '-';
                            } else {
                                const companies = user.companies.map(company => {
                                    company.locations = user.locations.filter(l => l.companyId == company._id);
                                    return company;
                                });
                                value = companies.map(company => company.name).join(', ');
                            }
                            break;
                        case this.props.t('Allocated Time'):
                        case this.props.t('Allocated'):
                            value = record.timesheetLogDetail?.allocated || record.allocated || '';
                            break;
                        case this.props.t('Date of Work'):
                        case this.props.t('Created Date'):
                        case this.props.t('Start Date'):
                        case this.props.t('End Date'):
                        case this.props.t('Delay Date'):
                            value = moment.utc(record[column.accessor]).format('DD MMM YYYY');
                            break;
                        case this.props.t('Status'):
                            value = record.rentalIsFinished ? 'Finished' : 'Active';
                            break;
                        case this.props.t('Rental Company'):
                        case this.props.t('Company Name'):
                            value = record.rentalCompany?.name || 
                                   record.timesheetLogDetail?.company?.name ||
                                   record.company?.name ||
                                   '';
                            break;
                        default:
                            if (typeof column.accessor === 'string') {
                                try {
                                    value = column.accessor.split('.').reduce((obj, key) => obj?.[key], record);
                                } catch (err) {
                                    value = '';
                                }
                            } else if (typeof column.accessor === 'function') {
                                try {
                                    value = column.accessor(record);
                                } catch (err) {
                                    value = '';
                                }
                            } else {
                                value = record[column.accessor];
                            }
                    }

                    record_to_download[column.Header] = value || '';
                }
            });

            return record_to_download;
        });

        console.log('Record example:', dataSource[0]?.timesheetLogDetail); // Debug log
        console.log('Processed data:', data_to_download[0]); // Debug log

        if (data_to_download.length > 0) {
            this.setState({ dataToDownload: data_to_download }, () => {
                if (this.csvLink && this.csvLink.link) {
                    this.csvLink.link.click();
                }
            });
        }
    }

    getColumnsForCurrentReport() {
        switch (this.state.reportType) {
            case 'contractedwork':
                return this.state.contractedWorkColumns;
            case 'additionalwork':
                return this.state.additionalWorkColumns;
            case 'delays':
                return this.state.delaysColumns;
            case 'rentals':
                return this.state.rentalsColumns;
            default:
                return [];
        }
    }

    renderTableHeader() {
        let header = Object.keys(this.state.timesheetReportHeader)
        return header.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    getReport(reportType, filtersQueryString = '') {
        if (userCant(this.props.user, 'generalReport.manage')) {
            return;
        }

        console.log('Getting report:', reportType, filtersQueryString);

        switch (reportType) {
            case 'additionalwork':
                this.generateAdditionalReportDetail(filtersQueryString);
                break;
            case 'contractedwork':
                this.generateContractedWorkReportDetail(filtersQueryString);
                break;
            case 'delays':
                this.generateDelaysReportDetail(filtersQueryString);
                break;
            case 'rentals':
                this.generateRentalsReportDetail(filtersQueryString);
                break;
            default:
                console.log('No report type selected');
        }
    }

    getSomeCompanies() {
        if (userCant(this.props.user, 'companies.index')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/company?projectId=" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                companyTableData: res.data,
            }, () => this.state.companyTableData);
            return res.data
        })
    }

    getSomeUsers() {
        if (userCant(this.props.user, 'users.index')) {
            this.setState({
                initWorkFilters: true
            })
            return
        }

        let url = `${process.env.REACT_APP_API_URL}/api/user?projectId=${this.props.projectId}`;
        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                userTableData: res.data,
                initWorkFilters: true
            });
            return res.data
        })
    }

    valid = function (current) {
        return current.isAfter(this.state.fromDate);
    };

    Report() {
        if (this.state.reportType === 'additionalwork') {
            return <Row>
                <Col>
                    <ReactTable
                        ref={(r) => this.reactTable = r}
                        NoDataComponent={() => null}
                        data={this.state.additionalWorkReportData}
                        columns={this.state.additionalWorkColumns}
                        defaultPageSize={1}
                        pageSize={this.state.additionalWorkReportData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}

                        className="-striped -highlight primary-pagination"
                    />
                </Col>

            </Row>
        } else if (this.state.reportType === 'contractedwork') {
            return <Row>
                <Col>
                    <ReactTable
                        ref={(r) => this.reactTable = r}
                        NoDataComponent={() => null}
                        data={this.state.contractedWorkReportData}
                        columns={this.state.contractedWorkColumns}
                        defaultPageSize={1}
                        pageSize={this.state.contractedWorkReportData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}

                        className="-striped -highlight primary-pagination"
                    />
                </Col>

            </Row>
        } else if (this.state.reportType === 'delays') {
            return <Row>
                <Col>
                    <ReactTable
                        ref={(r) => this.reactTable = r}
                        NoDataComponent={() => null}
                        data={this.state.delaysReportData}
                        columns={this.state.delaysColumns}
                        defaultPageSize={1}
                        pageSize={this.state.delaysReportData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}

                        className="-striped -highlight primary-pagination"
                    />
                </Col>

            </Row>
        } else if (this.state.reportType === 'rentals') {
            return <Row>
                <Col>
                    <ReactTable
                        ref={(r) => this.reactTable = r}
                        NoDataComponent={() => null}
                        data={this.state.rentalsReportData}
                        columns={this.state.rentalsColumns}
                        defaultPageSize={1}
                        pageSize={this.state.rentalsReportData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight primary-pagination"
                    />
                </Col>
            </Row>
        }
    }

    renderLoadingProgress() {
        const progress = Object.values(this.state.loadingProgress).filter(Boolean).length;
        const total = Object.keys(this.state.loadingProgress).length;
        const percentage = Math.round((progress / total) * 100);
        
        return (
            <div className="loading-filters">
                <span>Initializing Filters... {percentage}%</span>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    render() {
        return <div className="content">
            {this.state.alert}
            <Row>
                <Col md="12" tag="h3">
                    {this.state.reportType ? 
                        this.props.t(this.state.reportTypeSelect.find(
                            opt => opt.value === this.state.reportType
                        )?.label || 'Reports') 
                        : this.props.t('Reports')
                    }
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Row>
                        <Col>
                            <Row className={'ml-1 d-flex align-items-center'} style={{'gap': '20px'}}>
                                <div>
                                    {this.state.initWorkFilters ? (
                                        <WorkFilters
                                            doFilter={this.getReport}
                                            filtersData={{
                                                buildings: this.state.projectStructureTableData,
                                                tasks: this.state.taskData,
                                                materials: this.state.materialData,
                                                instructions: this.state.instructionData,
                                                employeesList: this.state.userTableData,
                                                companiesList: this.state.companyTableData
                                            }}
                                            dateLimits={this.state.dateLimits}
                                            key={this.props.projectId}
                                        />
                                    ) : this.renderLoadingProgress()}
                                </div>
                                <div>
                                    <Button color="info" onClick={this.download}>
                                        {this.props.t('Download')}
                                    </Button>
                                </div>
                                {/* Only show total hours for contracted and additional work */}
                                {['contractedwork', 'additionalwork'].includes(this.state.reportType) && (
                                    <div>
                                        Total hours: {this.state.totalHours}
                                    </div>
                                )}
                            </Row>

                            <div>
                                <CSVLink
                                    data={this.state.dataToDownload || []}
                                    filename={`${this.state.reportType}_report.csv`}
                                    className="hidden"
                                    ref={(r) => this.csvLink = r}
                                    target="_blank"
                                />

                            </div>
                            <Row>
                                <Col>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <this.Report/>
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    }

    // New method to batch load related data
    batchLoadData = async () => {
        const endpoints = [
            "/api/projectstructure/project/",
            "/api/task/project/",
            "/api/material/project/",
            "/api/instruction/project/",
            "/api/user",
            "/api/company"
        ];

        try {
            const requests = endpoints.map(endpoint => 
                axios.get(`${process.env.REACT_APP_API_URL}${endpoint}${this.props.projectId}`, {
                    headers: { 'Authorization': localStorage.jwtToken }
                })
            );

            const responses = await Promise.all(requests);
            
            this.setState({
                projectStructureTableData: responses[0].data,
                taskData: responses[1].data,
                materialData: responses[2].data,
                instructionData: responses[3].data,
                userTableData: responses[4].data,
                companyTableData: responses[5].data,
                initWorkFilters: true
            });
        } catch (error) {
            console.error('Error batch loading data:', error);
            this.setState({ initWorkFilters: true }); // Enable filters even on error
        }
    }
}

const GeneralReport = withTranslation()(connect(mapStateToProps, null)(ConnectedTimesheetLogDetailReport));

export default GeneralReport;
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import Select from "react-select"
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap";

import { connect } from "react-redux";
import {userCant, userCan} from "../../../services/Authorization/authService";

const mapStateToProps = (state) => {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
};

class AddRentalCompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.rentalEquipmentTableData || [],  // Initialize with props
      description: "",
      typeModel: "",
      projectId: "ettest",
      rentalCompanyToAddValue: '',
      singleRentalEquipmentSelect: '',
      singleRentalEquipmentDescription: 'BOOM',
      companyFromAPI: [],
      selectedValueCompany: '',
      selectedValueDescriptionm: '',
      selectedValueTypeModel: '',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      company: null,
      deleteRentalEquipmentAlert: null,
      unableToDeleteRentalEquipmentAlert: null,
    };
    this.clearForm=this.clearForm.bind(this)
    this.handleDescriptionChange=this.handleDescriptionChange.bind(this)
    this.handleTypeModelChange=this.handleTypeModelChange.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteRentalEquipmentById=this.deleteRentalEquipmentById.bind(this)
    this.renderDropdownEditable=this.renderDropdownEditable.bind(this)
    this.deleteRentalEquipmentAlert = this.deleteRentalEquipmentAlert.bind(this)
    this.warningRentalEquipmentCantBeDeleted = this.warningRentalEquipmentCantBeDeleted.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    this.initializeData();
  }

  initializeData = async () => { 
    await this.fetchRentalCompanies();
    if (this.props.projectId) {
      await this.fetchRentalEquipment(this.props.projectId);
    }
  };

  componentDidUpdate(prevProps) {
    // Add check for rental companies updates
    if (this.props.rentalCompanyTableData !== prevProps.rentalCompanyTableData) {
      this.fetchRentalCompanies();
    }
    
    // Log all relevant prop changes
    const propsToCheck = ['projectId', 'rentalEquipmentTableData'];
    propsToCheck.forEach(prop => {
      if (this.props[prop] !== prevProps[prop]) {
        console.log(`🔄 Prop '${prop}' changed:`, {
          from: prevProps[prop],
          to: this.props[prop]
        });
      }
    });

    // Handle project changes
    if (this.props.projectId !== prevProps.projectId) {
      if (this.props.projectId) {
        this.fetchRentalEquipment(this.props.projectId);
      } else {
        this.syncTableData([]);
      }
    }

    // Sync with Redux data
    
  }

  fetchRentalEquipment = async (projectId) => {
    try {
      console.log('🔄 Fetching rental equipment:', {
        projectId,
        timestamp: new Date().toISOString()
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/rentalequipment/project/${projectId}`,
        {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
      );
     
      console.log(response.data)
      const newData = response.data || [];
      this.setState({ data: newData }, () => {})

    } catch (error) {
      console.error('❌ Error fetching rental equipment:', error);
    }
  };

  fetchRentalCompanies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/rentalcompany/`,
        {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
      );

      const options = response.data?.map(company => ({
        value: company._id,
        label: company.name || 'Unnamed Company'
      }));
      this.setState({ companyFromAPI: options });
    } catch (error) {
      console.error('Error fetching rental companies:', error);
      this.setState({ companyFromAPI: [] });
    }
  };

  formSubmitSuccess = async (e) => {
    e.preventDefault();
    
    try {
      const companyValue = this.state.company?.value;
      if (!companyValue) {
        console.error('No company selected');
        return;
      }

      console.log('Submitting new rental equipment...');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/rentalequipment/`,
        {
          rentalCompany: companyValue,
          description: this.state.description,
          typeModel: this.state.typeModel,
          projectId: this.props.projectId
        },
        {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
      ).then(res => {
        console.log(res)
      });

      console.log('Successfully added new rental equipment, refreshing data...');
      
      // Refresh the data
      await this.fetchRentalEquipment(this.props.projectId);
      
      // Clear the form
      this.setState({
        company: null,
        description: '',
        typeModel: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Update the company select handler
  handleCompanyChange = (selectedOption) => {
    console.log('Selected company:', selectedOption);
    this.setState({ company: selectedOption });
  };

  handleRentalEquipmentToAddText = (e) => {

    this.setState({ rentalEquipmentToAddValue: e.target.value }, () => this.state.rentalEquipmentToAddValue);
  }

  componentWillReceiveProps({ rentalEquipmentTableData, rentalCompanyTableData }) {
    this.setState({ ...this.state, rentalEquipmentTableData, rentalCompanyTableData }, () => this.state)
  }

  clearForm(){
    this.setState({
      company:'',
      description: '',
      typeModel: ''
    }, ()=>{console.log(this.state.description)})
  }

  handleDescriptionChange(e){
    console.log( "e.target.value Description")
    console.log( e.target.value)
    this.setState({description: e.target.value})
  }

  handleTypeModelChange(e){
    console.log( "e.target.value TypeModel")
    console.log( e.target.value)
    this.setState({typeModel: e.target.value})
  }

  dataTablelength(obj) {
    console.log(obj)
    return Object.keys(obj).length;
}

handleInputChange = (cellInfo, event) => {
  if (!cellInfo || !event) return;

  const newData = [...(this.props.rentalEquipmentTableData || [])];
  if (newData[cellInfo.index]) {
    newData[cellInfo.index][cellInfo.column.id] = event.target.value;
    this.setState({ data: newData });
  }
};

handleSelectInputChange= (cellInfo, event) => {
  console.log(event)
  let data = [...this.props.rentalEquipmentTableData];
  data[cellInfo.index][cellInfo.column.id] = event.value;

  this.setState({ data });
  this.setState({company:event.value}, ()=>{})
};

renderEditable(cellInfo) {
  // Early return if we don't have valid cellInfo
  if (!cellInfo) {
    return null;
  }

  // Safely access the data
  const rowData = this.state.data?.[cellInfo.index];
  const columnId = cellInfo.column?.id;
  const cellValue = rowData?.[columnId];
  const { editingRow } = this.state;

  return (
    <div>
      {editingRow === cellInfo.index ? (
        <Input
          maskChar=""
          mask=""
          placeholder=""
          onChange={(e) => this.handleInputChange(cellInfo, e)}
          value={cellValue}
          tag={InputMask}
        />
      ) : (
        <div>
          {cellValue}
        </div>
      )}
    </div>
  );
}

renderDropdownEditable(cellInfo) {
  if (!cellInfo || !cellInfo.original) {
    return null;
  }

  // Safely access the cell value and rental company data
  const rowData = this.props.rentalEquipmentTableData?.[cellInfo.index];
  const { editingRow } = this.state;

  return (
    <div>
      {editingRow === cellInfo.index ? (
        <Select
          className="react-select primary"
          classNamePrefix="react-select"
          name="company"
          defaultValue={rowData?.rentalCompany ? { 
            label: rowData.rentalCompany.name || '', 
            value: rowData.rentalCompany._id 
          } : null}
          onChange={this.handleSelectInputChange.bind(null, cellInfo)}
          options={this.state.companyFromAPI}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: '#000',
              backgroundColor: '#fff'
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? '#fff' : '#000',
              backgroundColor: state.isSelected ? '#007bff' : '#fff',
              '&:hover': {
                backgroundColor: '#f8f9fa',
                color: '#000'
              },
              cursor: 'pointer',
              padding: '8px 12px'
            }),
            control: (provided) => ({
              ...provided,
              minHeight: '38px'
            })
          }}
          menuPortalTarget={document.body}
        />
      ) : (
        <div>{rowData?.rentalCompany?.name || ''}</div>
      )}
    </div>
  );
}

saveRowData(cellInfo) {
  console.log("cellInfo")
  console.log(cellInfo)
  this.setState({ editContent: false }, () => console.log(cellInfo.original.name))

  axios.put(process.env.REACT_APP_API_URL + '/api/rentalequipment/', {
    _id: cellInfo.original._id,
    typeModel: cellInfo.original.typeModel,
    description: cellInfo.original.description,
    company:cellInfo.original.company

  }, {
    headers: {
      'Authorization': localStorage.jwtToken
    }
  }).then(res => {
    console.log(res)
    this.setState({
      editingRow: -1,
      isDirty: false
    })
    return res.data
  })
    .catch((error) => {
      ;
    })
}


toggleEditable(index) {
  console.log(index);
  const { isDirty } = this.state;
  if (!isDirty) {
    this.setState({
      editingRow: index,
      isDirty: true
    });
  }
  else {
    this.setState({
      editingRow: -1,
      isDirty: false
    })
  }
}


renderActiveButtons(row) {

  if (userCant(this.props.user, 'equipment.manage')) {
    return ''
  }

    return (<div>
    <Button
      onClick={() => this.toggleEditable(row.index)}
      size="xs"
      className="btn-icon btn-neutral btn btn-info"
    ><i className="fa fa-edit"
      /></Button>
    <Button
      onClick={() => this.saveRowData(row)}
      size="xs"
      className="btn-icon btn-neutral btn btn-info"
    ><i className="fa fa-save"
      /></Button>
          <Button
        color="danger"
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteRentalEquipmentAlert(row.original)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
  </div>)


}
deleteRentalEquipmentById(rentalEquipmentId) {
  var self = this
  axios.delete(process.env.REACT_APP_API_URL + '/api/rentalequipment/' + rentalEquipmentId, {
    headers: {
      'Authorization': localStorage.jwtToken
    }
  }).then(res => {
    this.setState({deleteRentalEquipmentAlert:null}, ()=>{})
    this.fetchRentalEquipment(this.props.projectId)
    return res
  })
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 403 || error.response.status===409 ) {
        self.warningRentalEquipmentCantBeDeleted()
        self.setState({ deleteRentalEquipmentAlert: null }, () => { })
        //  return self.setState({responseOnDeleteRequest:'Delete associated rental equipment first'},()=>{})
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });
}

deleteRentalEquipmentAlert(rentalId) {
console.log(rentalId)
  this.setState({
    deleteRentalEquipmentAlert:
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => this.deleteRentalEquipmentById(rentalId._id)}
        onCancel={() => this.setState({ deleteRentalEquipmentAlert: null }, () => { })}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={this.props.t("Delete")}
        cancelBtnText={this.props.t("Cancel")}
        showCancel
      >
        {this.props.t("Delete Rental Equipment")}
        <br/>
        {rentalId?.rentalCompany?.name + ' | ' + rentalId?.description + ' | ' + rentalId?.typeModel}

      </ReactBSAlert>
  }, () => { })
}

warningRentalEquipmentCantBeDeleted(){
  this.setState({
    visible:
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => this.setState({ visible: null }, () => { })}
        // onCancel={() => this.setState({ deleteRentalCompanyAlert: null }, () => { })}
        confirmBtnBsStyle="info"
        // cancelBtnBsStyle="danger"
        confirmBtnText={this.props.t("Close")}
        // cancelBtnText="Cancel"
        // showCancel
      >
        {this.props.t("Unable to delete rental equipment as its associated with a rental record")}.

      </ReactBSAlert>
  }, () => { })
}

// Add a method to sync data

  // if (JSON.stringify(this.state.data) !== JSON.stringify(newData)) {
  //   this.setState({ data: newData || [] }, () => {
  //     console.log('📊 Table data updated:', {
  //       length: this.state.data.length,
  //       data: this.state.data
  //     });
  //   });}


  render() {
    const tableData = this.state.data || [];

    const { companyFromAPI, company } = this.state;

    return (
      <>
          {this.state.deleteRentalEquipmentAlert}
          {this.state.visible}
        <div className="content">
          {/* Project identifier */}
          <div className="mb-3">
            <small className="text-muted">
              {this.props.t("Current Project")}: {this.props?.project || 'None'} 
            </small>
          </div>

          {/* Data count display */}
          <div className="mb-2">
            <small className="text-info">
              {this.props.t("Loaded Equipment Items")}: {tableData.length}
            </small>
          </div>

          {userCan(this.props.user, 'equipment.manage') && (
            <>
              <h4>
                {this.props.t("Add Rental Equipment")}
                <small className="text-muted ml-2">
                  ({tableData.length} {this.props.t("items")})
                </small>
              </h4>
              <Form className="form-horizontal" onSubmit={this.formSubmitSuccess}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>{this.props.t("Rental Company")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="rentalCompany"
                        value={company}
                        onChange={(selectedOption) => {
                          this.setState({ company: selectedOption });
                        }}
                        options={companyFromAPI}
                        placeholder={this.props.t("Select Rental Company")}
                        isSearchable={true}
                        isClearable={true}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                            color: '#000',
                            backgroundColor: '#fff'
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? '#fff' : '#000',
                            backgroundColor: state.isSelected ? '#007bff' : '#fff',
                            '&:hover': {
                              backgroundColor: '#f8f9fa',
                              color: '#000'
                            },
                            cursor: 'pointer',
                            padding: '8px 12px'
                          }),
                          control: (provided) => ({
                            ...provided,
                            minHeight: '38px'
                          })
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#007bff',
                            primary25: '#f8f9fa',
                            neutral0: '#fff',
                            neutral80: '#000'
                          },
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label>{this.props.t("Description")}</label>
                      <Input
                          onChange={this.handleDescriptionChange}
                          name="description"
                          value={this.state.description}
                          type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label>{this.props.t("Type / Model")}</label>
                      <Input
                          onChange={this.handleTypeModelChange}
                          name="typemodel"
                          value={this.state.typeModel}
                          type="text"
                      />
                    </FormGroup>
                  </Col>

                </Row>
                <Row>
                  <Col md={3}>
                    <Button color="info" type="submit">{this.props.t("Add Rental Equipment")}</Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}

          <ReactTable
            data={tableData}
            columns={[
              {
                Header: this.props.t("Rental Equipment"),
                accessor: "_id",
                show: false
              }, {
                Header:this.props.t("Rental Equipment Company"),
                accessor: "rentalCompany.name",
                // Cell: this.renderDropdownEditable
              },
              {
                Header: this.props.t("Equipment Description"),
                accessor: "description",
                Cell: this.renderEditable
              }, {
                Header: this.props.t("Type / Model"),
                accessor: "typeModel",
                Cell: this.renderEditable
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value)))
              }

            ]}
            defaultPageSize={Math.max(1, tableData.length)}
            pageSize={Math.max(1, tableData.length)}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
            noDataText={this.props.t("No rental equipment found")}
          />
        </div>
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, null)(AddRentalCompanyTable))


import React, {useEffect, useRef, useState, useMemo, Suspense} from "react";
import {useTranslation} from 'react-i18next';
import {Dock} from "react-dock"
import {FormGroup, Row, Form, Button, Card, CardHeader, CardTitle, CardBody, CardGroup, Col} from "reactstrap";
import close from "../../../assets/img/close.svg";
import Select from "react-select";
import _ from 'lodash';
import PlanManagement from "../PlanManagement/PlanManagement";
import {useDispatch, useSelector} from "react-redux";
import {setScheduleItem} from "../../../actions/scheduleActions";
import Datetime from "react-datetime";
import moment from "moment";

// 1. Memoize expensive data transformations
const useMemoizedOptions = (data, labelFn) => {
    return React.useMemo(() => {
        // Add safety check for data
        if (!Array.isArray(data)) {
            return [];
        }
        return data.map(item => ({
            value: item._id,
            label: labelFn(item)
        }));
    }, [data]);
};

// Lazy load the filters modal component
const FiltersModalContent = React.lazy(() => Promise.resolve({
    default: ({
        isOpen,
        setIsOpen,
        filters,
        setFilters,
        reportType,
        setReportType,
        props,
        scheduleItem,
        // ... other props
    }) => {
        // Move all the filter options logic inside the modal component
        return (
            <Dock
                position='right'
                isVisible={isOpen}
                duration={300}
                size={window.innerWidth < 600 ? 1 : 0.865}
            >
                <Card>
                    <CardHeader>
                        <CardTitle tag={'h4'}>
                            <Button color="warning" onClick={(e) => {
                                setIsOpen(false)
                            }}>{t('close')}</Button>

                            <span className={'ml-2'}>{t('Filter selection')}</span>

                        </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <Form className="form-horizontal" onSubmit={(e) => props.doFilter(reportType?.value || '', filtersToQueryString())}>

                            <Row>
                                <Col>
                                    <CardGroup>
                                        <Card>
                                            {
                                                reportType && ['contractedwork', 'additionalwork'].includes(reportType?.value)
                                                &&
                                                <PlanManagement/>
                                            }
                                        </Card>

                                        <Card>
                                            <CardBody>
                                                <h7>{t('Report Type')}</h7>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="reportTypeSelect"
                                                    value={reportType}
                                                    onChange={value =>
                                                        setReportType(value)
                                                    }
                                                    options={reportTypeOptions}
                                                />

                                                <Row className={'my-1'}>
                                                    <Col>
                                                        <p>
                                                            <h7 style={{margin: '20px 0'}}>{t('Time Period (from date/ to date)')}</h7>
                                                            {resetField('dateRange')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row className={'my-1'}>
                                                    <Col md={6}>
                                                        <label>{t('From date')}:</label>
                                                        <Datetime
                                                            onChange={value => {
                                                                // Ensure we're storing a moment object
                                                                const momentValue = moment.isMoment(value) ? value : moment(value);
                                                                setFilters({
                                                                    ...filters,
                                                                    from: momentValue
                                                                });
                                                            }}
                                                            isValidDate={checkFromDate}
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            utc={true}
                                                            value={filters.from}
                                                            dateFormat={'DD MMM YYYY'}
                                                            inputProps={{placeholder: "From date"}}
                                                        />
                                                    </Col>

                                                    <Col md={6}>
                                                        <label>{t('To date')}:</label>
                                                        <Datetime
                                                            onChange={value => {
                                                                // Ensure we're storing a moment object
                                                                const momentValue = moment.isMoment(value) ? value : moment(value);
                                                                setFilters({
                                                                    ...filters,
                                                                    to: momentValue
                                                                });
                                                            }}
                                                            isValidDate={checkToDate}
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            utc={true}
                                                            value={filters.to}
                                                            dateFormat={'DD MMM YYYY'}
                                                            inputProps={{placeholder: "To date"}}
                                                        />
                                                    </Col>
                                                </Row>

                                                {reportType && ['contractedwork', 'additionalwork'].includes(reportType?.value) && <>
                                                    <p className={'m-0'}>
                                                        <h7>{t("Scheduled Item")}</h7>
                                                        {resetField('ScheduledItem')}
                                                    </p>
                                                    <FormGroup>
                                                        <p style={{
                                                            color: '#51cbce',
                                                            border: '1px #E3E3E3 solid',
                                                            padding: '5px',
                                                            borderRadius: '3%',
                                                            minHeight: '30px'
                                                        }}>{scheduleItem}</p>
                                                    </FormGroup>

                                                    <p className={'m-0'}>
                                                        <h7>{t('Building')}</h7>
                                                        {resetField('building')}
                                                    </p>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="building"
                                                            value={filters.building}
                                                            onChange={option => setFilters({
                                                                ...filters,
                                                                building: option
                                                            })}
                                                            options={buildings}
                                                            placeholder={t("Choose Building")}
                                                        />
                                                    </FormGroup>

                                                    <p className={'m-0'}>
                                                        <h7>{t('Level')}</h7>
                                                        {resetField('level')}
                                                    </p>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="level"
                                                            value={filters.level}
                                                            onChange={option => setFilters({
                                                                ...filters,
                                                                level: option
                                                            })}
                                                            options={filtersOptions.projectLevels}
                                                            placeholder={t("Choose Level")}
                                                        />
                                                    </FormGroup>

                                                    <p className={'m-0'}>
                                                        <h7>{t('Elevation')}</h7>
                                                        {resetField('elevation')}
                                                    </p>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="elevation"
                                                            value={filters.elevation}
                                                            onChange={option => setFilters({
                                                                ...filters,
                                                                elevation: option
                                                            })}
                                                            options={filtersOptions.projectElevations}
                                                            placeholder={t("Choose Elevation")}
                                                        />
                                                    </FormGroup>

                                                    <p className={'m-0'}>
                                                        <h7>{t('System')}</h7>
                                                        {resetField('system')}
                                                    </p>
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="system"
                                                            value={filters.system}
                                                            onChange={option => setFilters({
                                                                ...filters,
                                                                system: option
                                                            })}
                                                            options={filtersOptions.projectSystems}
                                                            placeholder={t("Choose System")}
                                                        />
                                                    </FormGroup>
                                                </>}

                                            </CardBody>
                                        </Card>

                                        <Card>
                                            {reportType && ['contractedwork', 'additionalwork'].includes(reportType?.value) &&
                                            <CardBody>
                                                {showInstructions && <>
                                                    <p className={'m-0'}>
                                                        <h7>Instruction</h7>
                                                        {resetField('instruction')}
                                                    </p>

                                                    <FormGroup>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="instruction"
                                                            value={filters.instruction}
                                                            onChange={option => setFilters({
                                                                ...filters,
                                                                instruction: option
                                                            })}
                                                            options={instructions}
                                                            placeholder={t("Choose Instruction")}
                                                        />

                                                    </FormGroup>
                                                </>}
                                                <p className={'m-0'}>
                                                    <h7>{t('Company')}</h7>
                                                    {resetField('company')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react  -select"
                                                        name="building"
                                                        value={filters.company}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            company: option
                                                        })}
                                                        options={companiesList}
                                                        placeholder={t("Choose Company")}
                                                    />
                                                </FormGroup>

                                                <p className={'m-0'}>
                                                    <h7>{t('Position')}</h7>
                                                    {resetField('position')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react  -select"
                                                        name="building"
                                                        value={filters.position}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            position: option
                                                        })}
                                                        options={companyPositions}
                                                        placeholder={t("Choose Company Position")}
                                                    />
                                                </FormGroup>

                                                <p className={'m-0'}>
                                                    <h7>{t('Employee')}</h7>
                                                    {resetField('employee')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react  -select"
                                                        name="building"
                                                        value={filters.employee}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            employee: option
                                                        })}
                                                        options={employeesList}
                                                        placeholder={t("Choose Employee")}
                                                    />
                                                </FormGroup>


                                                <p className={'m-0'}>
                                                    <h7>{t('Task')}</h7>
                                                    {resetField('task')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="taskName"
                                                        value={filters.task}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            task: option
                                                        })}
                                                        options={tasks}
                                                        placeholder={t("Choose Task")}
                                                    />
                                                </FormGroup>
                                                <p className={'m-0'}>
                                                    <h7>{t('Material')}</h7>
                                                    {resetField('material')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="material"
                                                        value={filters.material}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            material: option
                                                        })}
                                                        options={materials}
                                                        placeholder={t("Choose Task")}
                                                    />
                                                </FormGroup>
                                            </CardBody>
                                            }
                                        </Card>

                                    </CardGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Button color="info" type={'submit'}>{t('Apply')}</Button>
                                    <Button color="info" onClick={(e) => {
                                        resetFilters(e)
                                    }}>{t('Reset')}</Button>
                                </Col>
                            </Row>

                        </Form>
                    </CardBody>
                </Card>


            </Dock>
        );
    }
}));

export const WorkFilters = props => {
    const {t, i18n} = useTranslation();
    const [isOpen, setIsOpen] = useState(false)
    const [filters, setFilters] = useState({})
    const scheduleItem = useSelector((state) => state.schedule.scheduleItem)
    const scheduleItemId = useSelector((state) => state.schedule.scheduleItemId)
    const projectId = useSelector((state) => state.allOtherReducers.projectId)

    const [reportType, setReportType] = useState('');
    const [showInstructions, setShowInstructions] = useState(false);

    const [dateLimits, setDateLimits] = useState(props.dateLimits || {})
    const [reportTypeDateLimits, setReportTypeDateLimits] = useState({})

    // Ensure filtersData properties exist with default empty arrays
    const filtersData = {
        buildings: [],
        instructions: [],
        tasks: [],
        materials: [],
        employeesList: [],
        companiesList: [],
        ...props.filtersData
    };

    // Use the safe filtersData object
    const buildings = useMemoizedOptions(filtersData.buildings, 
        b => b.building
    );

    const instructions = useMemoizedOptions(filtersData.instructions,
        instruction => instruction.userDefinedInstructionNumber === undefined ? 
            "-" + ' | ' + instruction.instructionTitle : 
            instruction.userDefinedInstructionNumber + ' | ' + instruction.instructionTitle
    );

    const tasks = useMemoizedOptions(filtersData.tasks,
        task => task.taskName
    );

    const companiesList = useMemoizedOptions(filtersData.companiesList,
        company => company.name
    );

    const materials = useMemoizedOptions(filtersData.materials,
        material => material.materialName
    );

    // 3. Memoize the report type options
    const reportTypeOptions = useMemo(() => [
        {value: 'contractedwork', label: t('Contracted Work')},
        {value: 'additionalwork', label: t('Additional Work')},
        {value: 'delays', label: t('Delays')},
        {value: 'rentals', label: t('Rentals')} // Add rentals option
    ], [t]);

    // 4. Lazy initialize employeesList with safety check
    const [employeesList, setEmployeesList] = useState(() => {
        if (!Array.isArray(filtersData.employeesList)) {
            return [];
        }
        return filtersData.employeesList.map(user => ({
            value: user._id, 
            label: `${user.name} ${user.last_name || ''}`
        }));
    });

    const [companyPositions, setCompanyPositions] = useState([]);

    const dispatch = useDispatch()

    const [filtersOptions, setFiltersOptions] = useState(() => {
        return {
            projectLevels: [],
            projectElevations: [],
            projectSystems: []
        }
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Defer initialization until component is mounted
        setIsLoading(false);
    }, []);

    useEffect(() => {
        let reportValue = (reportType?.value || '').toLowerCase();
        setReportTypeDateLimits(dateLimits[reportValue] || {})
    }, [reportType])

    // 6. Optimize useEffect dependencies
    useEffect(() => {
        if (!filters.company) {
            setCompanyPositions([]);
            if (Array.isArray(filtersData.employeesList)) {
                setEmployeesList(filtersData.employeesList.map(user => ({
                    value: user._id,
                    label: `${user.name} ${user.last_name || ''}`
                })));
            } else {
                setEmployeesList([]);
            }
            return;
        }

        // Use memoized filtering with safety check
        const filteredEmployees = React.useMemo(() => {
            if (!Array.isArray(filtersData.employeesList)) {
                return [];
            }
            return filtersData.employeesList.filter(user => {
                const userCompanies = (user.companies || []).map(c => c._id);
                return userCompanies.includes(filters.company?.value);
            });
        }, [filters.company, filtersData.employeesList]);

        setEmployeesList(filteredEmployees.map(user => ({
            value: user._id,
            label: `${user.name} ${user.last_name || ''}`
        })));

        const companyObject = filtersData.companiesList.find(
            c => `${c._id}` === `${filters.company?.value}`
        );

        if (companyObject && Array.isArray(companyObject.positions)) {
            setCompanyPositions(companyObject.positions.map(position => ({
                value: position._id,
                label: position.name
            })));
        } else {
            setCompanyPositions([]);
        }
    }, [filters.company]);

    useEffect(() => {
        switch (reportType?.value) {
            case 'contractedwork':
                setFilters({
                    ...filters,
                    instruction: ''
                })
                setShowInstructions(false);
                break;
            case 'additionalwork':
                setShowInstructions(true);
                break;
            case 'delays':
                setFilters({
                    ...filters,
                    instruction: '',
                    building: '',
                    level: '',
                    elevation: '',
                    system: '',
                    task: '',
                    material: '',
                    employee: '',
                    company: '',
                    position: ''
                })
                dispatch(setScheduleItem(null));
                break;
        }

    }, [reportType]);

    useEffect(() => {
        var buildingObject = filtersData.buildings.find(b => `${b._id}` === `${filters?.building?.value}`)

        if (typeof buildingObject !== 'object' || buildingObject === null) {
            setFiltersOptions({
                ...filtersOptions,
                projectLevels: [],
                projectElevations: [],
                projectSystems: []
            })

            setFilters({
                ...filters,
                level: '',
                elevation: '',
                system: ''
            })
            return;
        }

        // Initialize arrays instead of objects
        let levels = [],
            elevations = [],
            systems = [];

        if (buildingObject.hasOwnProperty('levelsx') && Array.isArray(buildingObject.levelsx)) {
            levels = buildingObject.levelsx.map(level => ({
                value: level._id,
                label: level.name
            }));
        }

        if (buildingObject.hasOwnProperty('elevationsx') && Array.isArray(buildingObject.elevationsx)) {
            elevations = buildingObject.elevationsx.map(elevation => ({
                value: elevation._id,
                label: elevation.name
            }));
        }

        if (buildingObject.hasOwnProperty('systemsx') && Array.isArray(buildingObject.systemsx)) {
            systems = buildingObject.systemsx.map(system => ({
                value: system._id,
                label: system.name
            }));
        }

        setFiltersOptions({
            ...filtersOptions,
            projectLevels: levels,
            projectElevations: elevations,
            projectSystems: systems
        });

        setFilters({
            ...filters,
            level: '',
            elevation: '',
            system: ''
        });
    }, [filters.building])

    useEffect(() => {
        if (!filters.employee) {
            setFilters({
                ...filters,
                position: ''
            })

            if (filters.company) {
                let companyObject = filtersData.companiesList.find(c => `${c._id}` === `${filters.company?.value}`)

                setCompanyPositions(companyObject.positions.map(position => {
                    return {
                        value: position._id,
                        label: position.name
                    }
                }));
            }

            return;
        }

        let employeeObject = filtersData.employeesList.find(em => `${em._id}` == `${filters.employee?.value}`)

        let __companyPositions;

        setCompanyPositions(__companyPositions = employeeObject.positions.map(p => {
            return {
                value: p._id,
                label: p.name
            }
        }))


        if (filters.position) {
            if (!__companyPositions.find(_p => `${_p.value}` === `${filters.position?.value}`)) {
                setFilters({
                    ...filters,
                    position: ''
                })
            }
        }

    }, [filters.employee])

    const doFilter = e => {
        e.preventDefault();
        
        const queryParams = {
            projectId,
            ...(scheduleItemId && { scheduleItemId }),
            ...(filters.from && { from: moment(filters.from).format('YYYY-MM-DD') }),
            ...(filters.to && { to: moment(filters.to).format('YYYY-MM-DD') }),
            ...(filters.building?.value && { building: filters.building?.value }),
            ...(filters.level?.value && { level: filters.level?.value }),
            ...(filters.elevation?.value && { elevation: filters.elevation?.value }),
            ...(filters.system?.value && { system: filters.system?.value }),
            ...(filters.task?.value && { task: filters.task?.value }),
            ...(filters.material?.value && { material: filters.material?.value }),
            ...(filters.employee?.value && { employee: filters.employee?.value }),
            ...(filters.company?.value && { company: filters.company?.value }),
            ...(filters.position?.value && { position: filters.position?.value }),
            ...(filters.instruction?.value && { instruction: filters.instruction?.value })
        };

        const queryString = new URLSearchParams(
            Object.fromEntries(
                Object.entries(queryParams).filter(([_, v]) => v != null)
            )
        ).toString();

        console.log('Applying filters:', queryString);
        props.doFilter(reportType?.value || '', queryString);
        setIsOpen(false);
    };

    /**
     *
     * @returns {string}
     */
    const filtersToQueryString = () => {
        return new URLSearchParams(_.pickBy(_.mapValues(filters, item => {
            // Handle moment date objects
            if (moment.isMoment(item)) {
                return item.format('YYYY-MM-DD');
            }
            // Handle regular Date objects
            if (item instanceof Date) {
                return moment(item).format('YYYY-MM-DD');
            }
            // Handle select values
            return item?.value || item || '';
        }), value => value !== '' && value !== null && value !== undefined)).toString();
    }

    const resetFilters = () => {
        setFilters({});
        setReportType(null);
        dispatch(setScheduleItem(null));
        props.doFilter('', `projectId=${projectId}`);
        setIsOpen(false);
    };

    /**
     *
     * @param current
     * @returns {boolean|*}
     */
    const checkFromDate = current => {
        if (reportType && reportType?.value === 'delays') {
            return true;
        }

        if (!reportTypeDateLimits.start) {
            return true;
        }

        const startDate = moment(reportTypeDateLimits.start);
        return current.isSameOrAfter(startDate, 'day');
    }

    /**
     *
     * @param current
     * @returns {boolean|*}
     */
    const checkToDate = current => {
        if (reportType && reportType?.value === 'delays') {
            return true;
        }

        if (!reportTypeDateLimits.end) {
            return true;
        }

        const endDate = moment(reportTypeDateLimits.end).add(1, 'day');
        return current.isSameOrBefore(endDate, 'day');
    }

    const resetField = field => {
        return <a href={'#'} className={'pull-right'} onClick={e => {
            e.preventDefault();

            switch (field) {
                case 'dateRange':
                    const momentValue = moment.isMoment(value) ? value : moment(value);
                    setFilters({
                  
                        ...filters,
                        from: momentValue,
                        to: momentValue
                    })
                    break;
                case 'ScheduledItem':
                    dispatch(setScheduleItem(null))
                    setFilters({
                        ...filters,
                        scheduleItemId: ''
                    })
                    break;
                default:
                    setFilters({
                        ...filters,
                        [field]: ''
                    })
            }

        }}>
            {t('Reset')}
        </a>
    }

    const filtersModal = () => {
        return <Dock
            position='right'
            isVisible={isOpen}
            duration={300}
            size={window.innerWidth < 600 ? 1 : 0.865}
        >
            <Card>
                <CardHeader>
                    <CardTitle tag={'h4'}>
                        <Button color="warning" onClick={() => setIsOpen(false)}>
                            {t('close')}
                        </Button>
                        <span className={'ml-2'}>{t('Filter selection')}</span>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Form className="form-horizontal" onSubmit={doFilter}>
                        <Row>
                            <Col>
                                <CardGroup>
                                    <Card>
                                        {
                                            reportType && ['contractedwork', 'additionalwork'].includes(reportType?.value)
                                            &&
                                            <PlanManagement/>
                                        }
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <h7>{t('Report Type')}</h7>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="reportTypeSelect"
                                                value={reportType}
                                                onChange={value =>
                                                    setReportType(value)
                                                }
                                                options={reportTypeOptions}
                                            />

                                            <Row className={'my-1'}>
                                                <Col>
                                                    <p>
                                                        <h7 style={{margin: '20px 0'}}>{t('Time Period (from date/ to date)')}</h7>
                                                        {resetField('dateRange')}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className={'my-1'}>
                                                <Col md={6}>
                                                    <label>{t('From date')}:</label>
                                                    <Datetime
                                                        onChange={value => {
                                                            // Ensure we're storing a moment object
                                                            const momentValue = moment.isMoment(value) ? value : moment(value);
                                                            setFilters({
                                                                ...filters,
                                                                from: momentValue
                                                            });
                                                        }}
                                                        isValidDate={checkFromDate}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        utc={true}
                                                        value={filters.from}
                                                        dateFormat={'DD MMM YYYY'}
                                                        inputProps={{placeholder: "From date"}}
                                                    />
                                                </Col>

                                                <Col md={6}>
                                                    <label>{t('To date')}:</label>
                                                    <Datetime
                                                        onChange={value => {
                                                            // Ensure we're storing a moment object
                                                            const momentValue = moment.isMoment(value) ? value : moment(value);
                                                            setFilters({
                                                                ...filters,
                                                                to: momentValue
                                                            });
                                                        }}
                                                        isValidDate={checkToDate}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        utc={true}
                                                        value={filters.to}
                                                        dateFormat={'DD MMM YYYY'}
                                                        inputProps={{placeholder: "To date"}}
                                                    />
                                                </Col>
                                            </Row>

                                            {reportType && ['contractedwork', 'additionalwork'].includes(reportType?.value) && <>
                                                <p className={'m-0'}>
                                                    <h7>{t("Scheduled Item")}</h7>
                                                    {resetField('ScheduledItem')}
                                                </p>
                                                <FormGroup>
                                                    <p style={{
                                                        color: '#51cbce',
                                                        border: '1px #E3E3E3 solid',
                                                        padding: '5px',
                                                        borderRadius: '3%',
                                                        minHeight: '30px'
                                                    }}>{scheduleItem}</p>
                                                </FormGroup>

                                                <p className={'m-0'}>
                                                    <h7>{t('Building')}</h7>
                                                    {resetField('building')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="building"
                                                        value={filters.building}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            building: option
                                                        })}
                                                        options={buildings}
                                                        placeholder={t("Choose Building")}
                                                    />
                                                </FormGroup>

                                                <p className={'m-0'}>
                                                    <h7>{t('Level')}</h7>
                                                    {resetField('level')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="level"
                                                        value={filters.level}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            level: option
                                                        })}
                                                        options={filtersOptions.projectLevels}
                                                        placeholder={t("Choose Level")}
                                                    />
                                                </FormGroup>

                                                <p className={'m-0'}>
                                                    <h7>{t('Elevation')}</h7>
                                                    {resetField('elevation')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="elevation"
                                                        value={filters.elevation}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            elevation: option
                                                        })}
                                                        options={filtersOptions.projectElevations}
                                                        placeholder={t("Choose Elevation")}
                                                    />
                                                </FormGroup>

                                                <p className={'m-0'}>
                                                    <h7>{t('System')}</h7>
                                                    {resetField('system')}
                                                </p>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="system"
                                                        value={filters.system}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            system: option
                                                        })}
                                                        options={filtersOptions.projectSystems}
                                                        placeholder={t("Choose System")}
                                                    />
                                                </FormGroup>
                                            </>}

                                        </CardBody>
                                    </Card>

                                    <Card>
                                        {reportType && ['contractedwork', 'additionalwork'].includes(reportType?.value) &&
                                        <CardBody>
                                            {showInstructions && <>
                                                <p className={'m-0'}>
                                                    <h7>Instruction</h7>
                                                    {resetField('instruction')}
                                                </p>

                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="instruction"
                                                        value={filters.instruction}
                                                        onChange={option => setFilters({
                                                            ...filters,
                                                            instruction: option
                                                        })}
                                                        options={instructions}
                                                        placeholder={t("Choose Instruction")}
                                                    />

                                                </FormGroup>
                                            </>}
                                            <p className={'m-0'}>
                                                <h7>{t('Company')}</h7>
                                                {resetField('company')}
                                            </p>
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react  -select"
                                                    name="building"
                                                    value={filters.company}
                                                    onChange={option => setFilters({
                                                        ...filters,
                                                        company: option
                                                    })}
                                                    options={companiesList}
                                                    placeholder={t("Choose Company")}
                                                />
                                            </FormGroup>

                                            <p className={'m-0'}>
                                                <h7>{t('Position')}</h7>
                                                {resetField('position')}
                                            </p>
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react  -select"
                                                    name="building"
                                                    value={filters.position}
                                                    onChange={option => setFilters({
                                                        ...filters,
                                                        position: option
                                                    })}
                                                    options={companyPositions}
                                                    placeholder={t("Choose Company Position")}
                                                />
                                            </FormGroup>

                                            <p className={'m-0'}>
                                                <h7>{t('Employee')}</h7>
                                                {resetField('employee')}
                                            </p>
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react  -select"
                                                    name="building"
                                                    value={filters.employee}
                                                    onChange={option => setFilters({
                                                        ...filters,
                                                        employee: option
                                                    })}
                                                    options={employeesList}
                                                    placeholder={t("Choose Employee")}
                                                />
                                            </FormGroup>


                                            <p className={'m-0'}>
                                                <h7>{t('Task')}</h7>
                                                {resetField('task')}
                                            </p>
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="taskName"
                                                    value={filters.task}
                                                    onChange={option => setFilters({
                                                        ...filters,
                                                        task: option
                                                    })}
                                                    options={tasks}
                                                    placeholder={t("Choose Task")}
                                                />
                                            </FormGroup>
                                            <p className={'m-0'}>
                                                <h7>{t('Material')}</h7>
                                                {resetField('material')}
                                            </p>
                                            <FormGroup>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="material"
                                                    value={filters.material}
                                                    onChange={option => setFilters({
                                                        ...filters,
                                                        material: option
                                                    })}
                                                    options={materials}
                                                    placeholder={t("Choose Task")}
                                                />
                                            </FormGroup>
                                        </CardBody>
                                        }
                                    </Card>

                                </CardGroup>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Button color="info" type="submit">{t('Apply')}</Button>
                                <Button color="info" onClick={resetFilters}>{t('Reset')}</Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </Dock>
    };

    return (
        <>
            <div className="photo__filter">
                <b>{t('REPORT TYPE & FILTER')}</b>
                <button 
                    className="photo__btn"
                    onClick={() => setIsOpen(true)}
                >
                    <i className="fa fa-filter"></i>
                </button>
            </div>
            {filtersModal()}
        </>
    );
}
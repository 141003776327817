import React from "react";

import {
  Button,
  Input,
  Row,
  Col,
  Form,
  FormGroup,
  CardBody
} from "reactstrap";

import { connect } from "react-redux";
import { addGeneralComment } from 'actions/index'
import Datetime from 'react-datetime';
import classnames from "classnames";
import axios from "axios";
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return {
    addGeneralComment: generalComment => dispatch(addGeneralComment(generalComment)),

  };
}

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    generalComment: state.generalComment
  };
}

class ConnectedAddGeneralCommentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date: '',
      generalCommentToAddValue: "",
      errors: {}
    }
    this.addGeneralComments = this.addGeneralComments.bind(this)
    this.clearForm=this.clearForm.bind(this)

  }
  componentDidMount(props) {

    if(this.props.editComment){
      this.setState({
        generalCommentToAddValue: this.props.editComment.generalComment
      })
    }
  }
  addGeneralComments(event, generalComment) {
    event.preventDefault();
    event.persist()

    this.setState({
      errors:{}
    })

    if(this.props.editComment){
    return  axios.put(process.env.REACT_APP_API_URL + '/api/generalComment/', {
        _id: this.props.editComment._id,
        generalComment: this.state.generalCommentToAddValue,

      }, {
        headers: {
          'Authorization': localStorage.jwtToken
        }
      }).then(res => {
        this.clearForm(res.data)
        return res.data
      }).catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })
    }

  
    const { date } = this.state;
    var projectId = this.props.projectId
    var reportDate = this.props.reportDate
    this.props.addGeneralComment({ generalComment, projectId, reportDate }).then((r) => {

      event.target.reset();
     this.clearForm(null,r.payload)
    })
      .catch((err) => {
        this.setState({
          errors: err.response.data
        })
      })
  }

  handleGeneralCommentToAddText = (e) => {

    this.setState({ generalCommentToAddValue: e.target.value }, () => this.state.generalCommentToAddValue);
  }

  clearForm(comment=null,newComment=null) {
    this.setState({
      generalCommentToAddValue: '',
      errors:{}
    })

    this.props.hideCommentForm(comment,newComment)

  }


  componentWillReceiveProps({ dateSelected, generalCommentData }) {
    this.setState({ ...this.state, dateSelected, generalCommentData }, () => this.state)
  }

  render() {
    const {errors} = this.state
    return (
      <>
          <Row>
            <Col>
              <CardBody>
                <h3>
                  <Button
                      className='mr-2'
                      color="info"
                      onClick={() => this.clearForm()}
                  >
                    {this.props.t("Cancel")}
                  </Button>
                </h3>
              <Form  onSubmit={(e) => this.addGeneralComments(e, this.state.generalCommentToAddValue)}>
                <Row>

                  <Col md={12}>
                    <label><b>{this.props.t('Comment')}</b></label>

                    <Input
                        type="textarea"
                        onChange={(e) => this.setState({ generalCommentToAddValue: `${e.target.value}` })}
                        value={this.state.generalCommentToAddValue}
                        className={classnames('form-control', {
                          'is-invalid': errors.generalComment
                        })}
                    />

                    {errors.generalComment && (
                        <div className="invalid-feedback d-block">{errors.generalComment}</div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                        color="info"
                        type="submit"
                        className='w-100'
                    >
                      {this.props.t(`${this.props.editComment ? 'Edit' : 'Add'} General Comment`)}
                    </Button>
                  </Col>

                </Row>
              </Form>
              </CardBody>
            </Col>
          </Row>

      </>
    );
  }
}

const AddGeneralCommentsTable = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedAddGeneralCommentsTable));
export default AddGeneralCommentsTable;

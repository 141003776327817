import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import Select from "react-select"
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import moment from "moment"
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { StyleSheet, css } from 'aphrodite';
import { addRental } from '../../../actions/rentalActions';
import classnames from 'classnames';

// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
  Form,
  FormGroup,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';





class ConnectedAddRental extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      company: '',
      companyFromAPI: [],
      rentalCompanyFromAPI: [],
      rentalEquipmentFromAPI: [],
      rentalEquipment: '',
      rentalDate: '',
      comment: 'test',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      alert: null,
      rentalFinishedDate: null,
      saveDateErrorVisible: false,
      deleteRentalError: false,
      errors: ''
    }

    this.renderEditableText = this.renderEditableText.bind(this);
    this.renderEditableNumber = this.renderEditableNumber.bind(this);
    this.renderEditableDate = this.renderEditableDate.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteRentalById = this.deleteRentalById.bind(this)
    this.populateDropDownRentalCompany = this.populateDropDownRentalCompany.bind(this)
    this.stopRental = this.stopRental.bind(this)
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this)
    this.renderEditableFinishDate = this.renderEditableFinishDate.bind(this)
    this.handleInputChangeFinishDate = this.handleInputChangeFinishDate.bind(this)
    this.saveDateErrorOnDismiss = this.saveDateErrorOnDismiss.bind(this)
    this.deleteRentalErrorOnDismiss = this.deleteRentalErrorOnDismiss.bind(this)

  }


  populateDropDownCompany(data) {


    var testdata = data
    const options = testdata.map(company => {
      return { value: company.name, label: company.name }
    })
    this.setState({ companyFromAPI: options }, () => console.log(this.state.companyFromAPI))
  }

  populateDropDownRentalCompany(data) {


    var testdata = data
    const options = testdata.map(company => {
      return { value: company.name, label: company.name }
    })
    this.setState({ rentalCompanyFromAPI: options }, () => console.log(this.state.rentalCompanyFromAPI))
  }

  populateDropDownRentalEquipment(data) {
    if (!data) return;
    
    const options = data.map(rental => ({
      value: rental._id,
      label: (
        <div style={{ display: 'flex', gap: '10px' }}>
          <div style={{ flex: '1', minWidth: '100px' }}>{rental?.rentalCompany?.name || ''}</div>
          <div style={{ flex: '1', minWidth: '100px' }}>{rental?.description || ''}</div>
          <div style={{ flex: '1', minWidth: '100px' }}>{rental?.typeModel || ''}</div>
        </div>
      ),
      singleValue: `${rental?.rentalCompany?.name || ''} | ${rental?.description || ''} | ${rental?.typeModel || ''}`
    }));

    this.setState({ 
      rentalEquipmentFromAPI: options 
    });
  }




  getRentalEquipmentById(rentalEquipmentId) {

    return axios.get(process.env.REACT_APP_API_URL + "/api/rentalequipment/" + rentalEquipmentId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => (res.data[0]))
      .catch((error) => {
        ;
      })
  }

  stopRental(rentalId) {


  }

  warningWithConfirmAndCancelMessage = (rentalId) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={this.props.t("Are you sure?")}
          // onConfirm={() => this.stopRental(rentalId)}
          onConfirm={() => this.deleteRentalById(rentalId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={this.props.t("OK")}
          cancelBtnText={this.props.t("Cancel")}
          showCancel
        >
          {this.props.t("To record an increase of hire length you can choose to edit the end date in the rentals table or add new rental")}.
        </ReactBSAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    }, () => { });
  };
  formSubmitSuccess(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    var gotRentalEquipmentId = formData.get('equipment')
    
    // Add validation before submission
    const startDate = moment(this.props.reportDate);
    const endDate = moment(this.state.rentalFinishedDate);

    if (!endDate.isValid()) {
      this.setState({ 
        errors: { rentalFinishedDate: this.props.t('Please enter a valid end date') }
      });
      return;
    }

    if (endDate.isBefore(startDate)) {
      this.setState({ 
        errors: { rentalFinishedDate: this.props.t('End date cannot be before start date') }
      });
      return;
    }

    // Clear any previous errors since validation passed
    this.setState({ errors: {} });

    this.getRentalEquipmentById(gotRentalEquipmentId).then(
      (response) => {
        var data = {
          companyRenting: formData.get('company'),
          rentalEquipmentId: gotRentalEquipmentId,
          description: response.description,
          typeModel: response.typeModel,
          rentalCompany: response.rentalCompany,
          rentalDate: this.props.reportDate,
          rentalFinishedDate: this.state.rentalFinishedDate,
          numberOfDays: formData.get("numberOfDays"),
          comment: formData.get("comment"),
          projectId: this.props.projectId
        }
    
        this.props.addRental(data)
      }
    ).finally(() => {
      if (!this.state.errors || Object.keys(this.state.errors).length === 0) {
        axios.get(process.env.REACT_APP_API_URL + "/api/rental/", {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            this.setState({
              data: res.data,
              company: '',
              description: '',
              typeModel: '',
              rentalEquipment: '',
              rentalDate: '',
              rentalFinishedDate: ''
            });

            this.props.getRentalsByProjectAndReportDate();
            this.clearForm();
            this.props.isAddRentalDockVisible();
          })
          .catch((error) => {
            console.error('Error fetching rentals:', error);
          });
      }
    });
  }

  componentWillReceiveProps({ companyTableData, rentalEquipmentTableData, rentalsTableData, errors }) {
    this.setState({ ...this.state, companyTableData, rentalEquipmentTableData, rentalsTableData, errors }, () => this.state)
    if (errors) {
      this.setState({ errors: errors }, () => {console.log(this.state.errors) });
    }
  }

  componentDidMount() {
    console.log('Component mounted');
    if (this.props.companyTableData) {
      this.populateDropDownCompany(this.props.companyTableData);
    }
    if (this.props.rentalCompanyTableData) {
      this.populateDropDownRentalCompany(this.props.rentalCompanyTableData);
    }
    if (this.props.rentalEquipmentTableData) {
      this.populateDropDownRentalEquipment(this.props.rentalEquipmentTableData);
    }
  }

  componentDidUpdate(prevProps) {
    // Immediately clear form when visibility changes to false
    if (prevProps.isVisible !== this.props.isVisible && !this.props.isVisible) {
      this.clearForm();
    }

    // Log visibility changes
    if (prevProps.isVisible !== this.props.isVisible) {
      console.log('Visibility changed:', {
        from: prevProps.isVisible,
        to: this.props.isVisible
      });
    }

    // Check for company data updates
    if (this.props.companyTableData !== prevProps.companyTableData) {
      this.populateDropDownCompany(this.props.companyTableData);
    }

    // Check for rental company data updates
    if (this.props.rentalCompanyTableData !== prevProps.rentalCompanyTableData) {
      this.populateDropDownRentalCompany(this.props.rentalCompanyTableData);
    }

    // Check for rental equipment data updates
    if (this.props.rentalEquipmentTableData !== prevProps.rentalEquipmentTableData) {
      this.populateDropDownRentalEquipment(this.props.rentalEquipmentTableData);
    }

    // Handle visibility changes - clear form when closing
    if (prevProps.isVisible && !this.props.isVisible) {
      console.log('Form closing, clearing data');
      this.clearForm();
    }

    // Repopulate data when opening
    if (!prevProps.isVisible && this.props.isVisible) {
      console.log('Form opening, populating data');
      if (this.props.companyTableData) {
        this.populateDropDownCompany(this.props.companyTableData);
      }
      if (this.props.rentalCompanyTableData) {
        this.populateDropDownRentalCompany(this.props.rentalCompanyTableData);
      }
      if (this.props.rentalEquipmentTableData) {
        this.populateDropDownRentalEquipment(this.props.rentalEquipmentTableData);
      }
    }
  }

  clearForm() {
    console.log('Clearing form');
    this.setState({
      company: null,
      description: "",
      typeModel: "",
      rentalEquipment: null,
      rentalDate: "",
      rentalFinishedDate: null,
      comment: "",
      // Also clear the dropdown options if needed
      companyFromAPI: [],
      rentalCompanyFromAPI: [],
      rentalEquipmentFromAPI: []
    }, () => {
      console.log('Form cleared, state:', this.state);
    });
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.rentalsTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };



  renderEditableText(cellInfo) {



    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];
    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask=""
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : (<div >
          {cellInfo.value}
        </div>)}
      </div>
    );
  }

  renderEditableNumber(cellInfo) {
    const { editingRow } = this.state;

    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];

    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask="999"
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : ((<div >
          {cellInfo.value}
        </div>))
        }
      </div>

    );
  }

  handleInputChangeFinishDate = (e, cellInfo) => {

    let data = [...this.props.rentalsTableData];
    data[cellInfo.index][cellInfo.column.id] = e.target.value;
    // this.setState({ data });
  }
  renderEditableDate(cellInfo) {

    const { editingRow } = this.state;
    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask="99/99/9999"
            placeholder="dd/mm/yyyy"
            onChange={(e) => this.handleInputChangeFinishDate(e, cellInfo)}
            tag={InputMask}
            permanents={[2, 5]}
          />

        )
          : (
            (<div >
              {moment.utc(cellInfo.value).format('DD MMM YYYY')}
            </div>
            )
          )}
      </div>

    );
  }






  renderEditableFinishDate(cellInfo) {
    const { editingRow } = this.state;
    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];

    return (



      <Datetime
        onChange={value =>
          this.setState({ rentalFinishDate: value })
        }
        closeOnSelect={true}
        timeFormat={false}
        utc={true}
        dateFormat={'DD MMM YYYY'}
        inputProps={{ placeholder: "Datetime Picker Here" }}
      />

    )
  }

  validateEndDate(startDate, finishDate) {

    //Check is a date 
    if (!moment(finishDate, "DD/MM/YYYY", true).isValid()) {
      return false
    }

    //format for compare
    var rentalFinishDate = moment(finishDate, "DD/MM/YYYY").format("DD/MM/YYYY")
    if (!moment(rentalFinishDate, "DD/MM/YYYY", true).isValid()) {
      return false
    }



    //Format rental Start Date for compare
    var rentalStart = moment(startDate).format("DD/MM/YYYY")
    //Check its still a valid date
    if (!moment(rentalStart, "DD/MM/YYYY", true).isValid()) {
      return false
    }

    //Compare
    if (moment(finishDate, "DD/MM/YYYY", true) >= moment(rentalStart, "DD/MM/YYYY", true)) {
      if (moment(finishDate, "DD/MM/YYYY", true) < moment(this.props.reportDate, "YYYY-MM-DD").format("DD/MM/YYYY")) {
        return false
      }
      else {
        return true
      }
    }
    else {
      return false
    }


  }
  saveRowData(cellInfo) {

    this.setState({ editContent: false }, () => console.log(cellInfo.original.numberEmployeesWorking))

    //validation
    if (!this.validateEndDate(cellInfo.original.rentalDate, cellInfo.original.rentalFinishedDate)) {
      this.setState({
        saveDateErrorVisible: true
      })


    }
    else {

      axios.put(process.env.REACT_APP_API_URL + '/api/rental/', {
        _id: cellInfo.original._id,
        // rentalDate: cellInfo.original.rentalDate,
        // numberOfDays: cellInfo.original.numberOfDays,
        // comment: cellInfo.original.comment,
        rentalFinishedDate: cellInfo.original.rentalFinishedDate
      }, {
        headers: {
          'Authorization': localStorage.jwtToken
        }
      }).then(res => {
        this.props.getRentalsByProjectAndReportDate()
        this.setState({
          editingRow: -1,
          isDirty: false
        })
        return res.data
      })
        .catch((error) => {
          ;
        })
    }
  }

  renderActiveButtons(row) {



    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.index)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        onClick={() => this.saveRowData(row)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-save"
        /></Button>
      {/* <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteRentalById(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button> */}
      <Button
        color="danger"
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.warningWithConfirmAndCancelMessage(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
    </div>)


  }
  deleteRentalById(rentalId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/rental/' + rentalId + '/date/' + this.props.reportDate, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.setState({
        alert: null
      }, () => { });
      this.props.getRentalsByProjectAndReportDate()


    })
      .catch((error) => {
        this.setState({
          alert: null,
          deleteRentalError: true
        }, () => { });

      })
  }



  toggleEditable(index) {

    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }

  saveDateErrorOnDismiss() {
    this.setState({ saveDateErrorVisible: !this.state.saveDateErrorVisible })
  }

  deleteRentalErrorOnDismiss() {
    this.setState({ deleteRentalError: !this.state.deleteRentalError })
  }

  handleClose = () => {
    this.clearForm();
    if (this.props.isAddRentalDockVisible) {
      this.props.isAddRentalDockVisible();  // Call the parent's toggle method
    }
  };

  render() {
    console.log(this.props.rentalsTableData)
    const s = StyleSheet.create({
      rdtPicker: { // this generate a new css class e.g rdtPicker_1wtftbl rather than target the original class
        'z-Index': '99999 !important'
      },
    });

    //define other properties here, use camel case(remember we are using Javascript)


    var yesterday = moment(this.props.reportDate);
    function valid(current) {
      return current.isAfter(yesterday);
    }

    console.log(this.props.rentalsTableData)
    const { errors } = this.state;
    return (



      <>
        <div className="content" >
          {this.state.alert}
          <Row>
            <Col className="text-right">
              <Button 
                className="btn-link"
                color="danger"
                onClick={this.handleClose}
                size="sm"
              >
                <i className="fa fa-times" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>{this.props.t("Add Rented Equipment")}</h4>
              <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>{this.props.t("Rented By")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="company"
                        value={this.state.company}
                        onChange={value =>
                          this.setState({ company: value })
                        }
                        options={this.state.companyFromAPI}
                        placeholder={this.state.selectedValueCompany}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label>{this.props.t("Equipment")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="equipment"
                        value={this.state.rentalEquipment}
                        onChange={value =>
                          this.setState({ rentalEquipment: value })
                        }
                        options={this.state.rentalEquipmentFromAPI}
                        placeholder={this.props.t("Select Equipment")}
                        isClearable
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '38px',
                            height: 'auto',
                            maxHeight: 'none'
                          }),
                          option: (base) => ({
                            ...base,
                            padding: '8px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word'
                          }),
                          singleValue: (base) => ({
                            ...base,
                            position: 'relative',
                            transform: 'none',
                            maxWidth: '100%',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            top: 'auto',
                            marginTop: '4px',
                            marginBottom: '4px'
                          }),
                          valueContainer: (base) => ({
                            ...base,
                            height: 'auto',
                            minHeight: '38px',
                            padding: '4px 8px',
                            whiteSpace: 'normal',
                            flexWrap: 'wrap'
                          }),
                          menu: (base) => ({
                            ...base,
                            width: '100%',
                            zIndex: 9999
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: '300px'
                          })
                        }}
                        formatOptionLabel={({ label, singleValue }, { context }) => 
                          context === 'value' ? singleValue : label
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label>{this.props.t("Rental End")}</label>
                      <Datetime
                        name='rentalFinishedDate'
                        onChange={value =>
                          this.setState({ rentalFinishedDate: value }, () => { })
                        }
                        value={this.state.rentalFinishedDate}
                        placeholder={this.props.t("Choose rental end date")}
                        closeOnSelect={true}
                        timeFormat={false}
                        utc={true}
                        dateFormat={'DD MMM YYYY'}
                        className={classnames('form-control form-control-lg', {
                          'is-invalid': errors.rentalFinishedDate
                        })}
                      />
                      {errors.rentalFinishedDate && (
                        <div className="invalid-feedback">{errors.rentalFinishedDate}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Button color="info" type="submit">{this.props.t("Add Rented Equipment")}</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentWillUnmount() {
    this.clearForm();
  }
}

ConnectedAddRental.propTypes = {
  errors: PropTypes.object.isRequired,
  addRental: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    errors: state.errors
  };
}

const AddRental = withTranslation()(connect(mapStateToProps, {addRental})(ConnectedAddRental));

export default AddRental;



import React from "react";
import axios from "axios"
import SiteReportTable from "./SiteReportHeader"
import SiteReportTitleandWeather from "./SiteReportTitleandWeather"
import SiteReportContractors from "./SiteReportContractors"
import SiteReportAdditionalWorks from "./SiteReportAdditionalWorks";
import SiteReportDelays from "./SiteReportDelays";
import SiteReportPositions from "./SiteReportPositions";
import SiteReportContractorSummary from "./SiteReportContractorSummary";
import SiteReportGeneralComments from "./SiteReportGeneralComments"
import SiteReportRentals from "./SiteReportRentals";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../../../vfs_fonts";
import helper from '../../../utils/general_helper.js'
import imageHelper from '../../../utils/image_helper.js'
import * as imageConversion from 'image-conversion';
import ReactBSAlert from "react-bootstrap-sweetalert";
import fetchTools from "../../../services/fetchTools"
import {withTranslation} from 'react-i18next';


import {connect} from "react-redux";
import {getGeneralCommentsByDateAndByProject} from "../../../actions/index";
import moment from "moment"
import ImageGallery from "views/components/imageDisplay/ImageGallery";


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import ContractorWorksFormByEmployee from "../ContractedWork/ContractedWorksFormByEmployee";
import {collapseTextChangeRangesAcrossMultipleVersions} from "typescript";
import {userCant} from "../../../services/Authorization/authService";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// const skyklassLogo = require('./images/logo-skyklass.png')
function mapDispatchToProps(dispatch) {
    return {
        getGeneralCommentsByDateAndByProject: (payload) => dispatch(getGeneralCommentsByDateAndByProject(payload))
    };
}

function mapStateToProps(state) {
    return {
        client: state.allOtherReducers.client,
        clientId: state.allOtherReducers.clientId,
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        generalCommentData: state.generalCommentData,
        user: state.auth.user
    };
}

class ConnectedSiteReportNextGenFour extends React.Component {
    constructor() {
        super();

        this.state = {
            reportDelays: [],
            reportPositions: [],
            reportWeather: [],
            reportEmployees: [],
            reportContractorSummary: [],
            reportContractedWork: [],
            reportAdditionalWork: [],
            reportGeneralComments: [],
            customerLogo: null,
            retreivedImages: [],
            selectedImageList: [],
            previous: 0,
            newWeatherData: [],
            reportRentals: [],
            imagesToAdd: [],
            reportWeather1: [],
            weatherDataTypes: '',
            formattedContractedWorkData: [],
            formattedContractedWorkData2: [],
            formattedTableStructure: [],
            formattedAdditionalTableStructure: [],
            retreivedLogo: [],
            logoToAdd: [],
            pdfWaitAlert: false,
            alert: null
        };

        this.getImages = this.getImages.bind(this)
        this.getLogo = this.getLogo.bind(this)
        this.addToSelectedImagesList = this.addToSelectedImagesList.bind(this)
        this.removeFromSelectedImagesList = this.removeFromSelectedImagesList.bind(this)
        this.getS3SignUrlGetObject = this.getS3SignUrlGetObject.bind(this)
        this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this)
        this.toDataURLLogos = this.toDataURLLogos.bind(this)
        this.getTagByName = this.getTagByName.bind(this)

    }

    componentWillMount() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }

        var projectId = this.props.projectId
        var date = this.props.reportDate

        if (this.props.projectId === undefined) {
            return {}
        } else {
            this.getReportDelays()
            this.getReportPositions()
            this.getReportContractorSummary()
            this.getReportWeather()
            this.getReportWeather1()
            this.getEmployeesCompressedListByDate(this.props.reportDate)
            this.getReportContractedWork()
            this.getReportAdditionalWork()
            this.getReportGeneralComments()
            this.getImages()
            this.getLogo()
            this.getReportRentals()
        }
    }

    componentDidUpdate(prevProps) {
        var projectId = this.props.projectId
        var date = this.props.reportDate
        if (this.props.projectId !== prevProps.projectId) {

            if (this.props.projectId === undefined) {
                return {}
            } else {
                this.getReportDelays()
                this.getReportPositions()
                this.getReportContractorSummary()
                this.getReportWeather()
                this.getReportWeather1()
                this.getEmployeesCompressedListByDate(this.props.reportDate)
                this.getReportContractedWork()
                this.getReportAdditionalWork()
                this.getReportGeneralComments()
                this.getReportRentals()
            }
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getReportDelays()
            this.getReportPositions()
            this.getReportContractorSummary()
            this.getReportWeather()
            this.getReportWeather1()
            this.getEmployeesCompressedListByDate(this.props.reportDate)
            this.getReportContractedWork()
            this.getReportAdditionalWork()
            this.getReportGeneralComments()
            this.getImages()
            this.getLogo()
            this.getReportRentals()
        }


    }

    warningWithConfirmAndCancelMessage = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{display: "block", marginTop: "-100px"}}
                    title={this.props.t("Report Generation")}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={false}
                >
                    Your report is being created and will download automatically in a moment.
                </ReactBSAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        }, () => {
        });
    };

    async getS3SignUrlGetObject(filename, filetype) {
        // {
        //   filename: filename,
        // },
        console.log("getS3SignUrlGetObject")
        console.log(filename)
        return axios.get(process.env.REACT_APP_API_URL + "/api/imagemanagement/presigned/" + filename, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }
        })
            .then(res => {
                console.log(res)
                console.log(res.data)
                return res.data
            }).catch((error) => {
                console.log(error)
                if (axios.isCancel(error)) {
                    console.log(error)
                }
                ;
            })

        // const presignedUrl = await response.json();

    }

    getReportDelays() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/delay/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    reportDelays: res.data
                }, () => this.state.reportDelays);

                return res.data
            }).catch((error) => {
            ;
        })
    }

    getReportRentals() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/rentals/open/project/" + this.props.projectId + "/date/" + this.props.reportDate, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    reportRentals: res.data
                }, () => {
                });
                return res.data
            }).catch((error) => {
            console.log(error);
        })
    }


    getReportContractorSummary() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/sitereport/contractorsummary/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    reportContractorSummary: res.data
                }, () => this.state.reportContractorSummary);

                return res.data
            }).catch((error) => {
            ;
        })
    }

    getReportContractedWork() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/contractorwork/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                var compositeScheduledItemId;
                const formattedData = res.data.map(elt => {
                        if (elt.elevation) {
                            var compositeElevation = elt?.elevation?.name
                        } else {
                            var compositeElevation = ''
                        }
                        if (elt.level) {
                            var compositeLevel = elt?.level?.name
                        } else {
                            var compositeLevel = ''
                        }
                        // if (elt.system|elt.system!=null) { var compositeSystem = elt.system.name } else { var compositeSystem = '' }
                        // if (elt.taskName.length > 0) { var compositeTaskName = elt.task.taskName } else { var compositeTaskName = '' }
                        // if (elt.materialName.length > 0) { var compositeMaterialName = elt.material.materialName } else { var compositeMaterialName = '' }
                        if (elt?.comment?.length > 0) {
                            var compositeComment = elt?.comment
                        } else {
                            var compositeComment = ''
                        }
                        if (elt.hasOwnProperty('system')) {
                            if (elt.system != null) {
                                var compositeSystem = elt?.system?.name
                            } else {
                                var compositeSystem = ''
                            }
                        }
                        if (elt.hasOwnProperty('task')) {
                            if (elt.task != null) {
                                var compositeTaskName = elt?.task?.taskName
                            } else {
                                var compositeTaskName = ''
                            }
                        } else {
                            var compositeTaskName = ''
                        }
                        if (elt.hasOwnProperty('material')) {
                            if (elt.material != null) {
                                var compositeMaterialName = elt?.material?.materialName
                            } else {
                                var compositeMaterialName = ''
                            }
                        } else {
                            var compositeMaterialName = ''
                        }


                        if (typeof elt.scheduledItemId === null) {
                            compositeScheduledItemId = elt.scheduledItem
                        } else {
                            compositeScheduledItemId = elt?.scheduledItemId?.full_path
                        }
                        var nameComma = ''
                        return [
                            // format output
                            elt?.building?.building
                            + ' '
                            + compositeSystem
                            + ': '
                            + compositeTaskName
                            + ' '
                            + compositeMaterialName
                            + ' '
                            + compositeLevel
                            + ' '
                            + compositeElevation
                            + '\n'
                            + this.props.t('Comments') + ': ' + compositeComment
                            + '\n'
                            + elt.employeesWorking.map((item, index) => {
                                if (index > 0) {
                                    nameComma = ' '
                                }
                                return nameComma + item?.user_id?.name + " " + item?.user_id?.last_name
                            }),
                            elt?.duration,
                            elt?._id,
                            elt?.projectId,
                            elt?.scheduledItem,
                            compositeScheduledItemId,
                            elt?.timesheetLogDetail
                        ]
                    }
                )
                const tableStructure = formattedData.map(x => {
                        return {
                            '_id': x[2],
                            'projectId': x[3],
                            'scheduledItem': x[5],
                            'contractedWorkDetail': x[0],
                            'timesheetLogDetail': x[6],
                            'duration': x[1]
                        }
                    }
                )
                this.setState({
                    reportContractedWork: res.data,
                    formattedContractedWorkData: formattedData,
                    formattedContractedWorkData2: formattedData,
                    formattedTableStructure: tableStructure
                }, () => console.log(this.state.reportContractedWork));
                console.log(res.data)
                return res.data
            }).catch((error) => {
            console.log(error)
            ;
        })
    }

    getReportAdditionalWork() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/additionalwork/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                var compositeScheduledItemId;
                const formattedData = res.data.map(elt => {
                    if (typeof elt.instruction != "undefined") {
                        var compositeInstruction = elt.instruction
                    } else {
                        var compositeInstruction = ''
                    }
                    if (elt.elevation) {
                        var compositeElevation = elt?.elevation?.name
                    } else {
                        var compositeElevation = ''
                    }
                    if (elt.level) {
                        var compositeLevel = elt?.level?.name
                    } else {
                        var compositeLevel = ''
                    }
                    if (elt.system) {
                        var compositeSystem = elt?.system?.name
                    } else {
                        var compositeSystem = ''
                    }
                    // if (elt.taskName.length > 0) { var compositeTaskName = elt.taskName } else { var compositeTaskName = '' }
                    // if (elt.materialName.length > 0) { var compositeMaterialName = elt.materialName } else { var compositeMaterialName = '' }
                    if (elt.comment.length > 0) {
                        var compositeComment = elt.comment
                    } else {
                        var compositeComment = ''
                    }
                    if (elt.hasOwnProperty('task')) {
                        if (elt.task != null) {
                            var compositeTaskName = elt?.task?.taskName
                        } else {
                            var compositeTaskName = ''
                        }
                    } else {
                        var compositeTaskName = ''
                    }
                    if (elt.hasOwnProperty('material')) {
                        if (elt.material != null) {
                            var compositeMaterialName = elt?.material?.materialName
                        } else {
                            var compositeMaterialName = ''
                        }
                    } else {
                        var compositeMaterialName = ''
                    }

                    if (typeof elt.scheduledItemId === null) {
                        compositeScheduledItemId = elt.scheduledItem
                    } else {
                        compositeScheduledItemId = elt?.scheduledItemId?.full_path
                    }
                    var nameComma = ''
                    return [
                        // format output
                        this.props.t('Instruction') + ': ' + compositeInstruction?.instructionTitle
                        + '\n'
                        + elt?.building?.building
                        + ' '
                        + compositeSystem
                        + ': '
                        + compositeTaskName
                        + ' '
                        + compositeMaterialName
                        + ' '
                        + compositeLevel
                        + ' '
                        + compositeElevation
                        + '\n'
                        + 'Comments: ' + compositeComment
                        + '\n'
                        + elt.employeesWorking.map((item, index) => {
                            if (index > 0) {
                                nameComma = ' '
                            }
                            return nameComma + item?.user_id?.name + " " + item?.user_id?.last_name
                        }),
                        elt.duration,
                        elt._id,
                        elt.projectId,
                        elt.scheduledItem,
                        compositeScheduledItemId,
                        elt.timesheetLogDetail,
                    ]
                })

                const tableStructure = formattedData.map(x => {
                        return {
                            '_id': x[2],
                            'projectId': x[3],
                            'scheduledItem': x[5],
                            'instruction': x[7],
                            'additionalWorkDetail': x[0],
                            'timesheetLogDetail': x[6],
                            'duration': x[1]
                        }
                    }
                )

                this.setState({
                    reportAdditionalWork: res.data,
                    formattedAdditionalTableStructure: tableStructure
                }, () => this.state.reportAdditionalWork);

                return res.data
            }).catch((error) => {
            ;
        })
    }

    getReportPositions() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/sitereport/positionssummary/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {

                let positionData = []
                var positionDetail = []

                res.data.forEach(
                    (element) => {
                        let splitString = element._id.split("|")
                        positionData["company"] = splitString[0]
                        positionData["position"] = splitString[1]
                        positionData["count"] = element.count
                        positionDetail.push(positionData)
                        positionData = []
                    },
                )
                this.setState({
                    reportPositions: positionDetail
                }, () => console.log(this.state.reportPositions));

                return res.data
            }).catch((error) => {
        })
    }

    getReportWeather() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/weather/simple/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                        reportWeather: res.data
                    }, () => {
                        let weatherData = [];
                        if (this.state.reportWeather != null | this.state.reportWeather.length > 0) {
                            console.log(this.state.reportWeather)
                            weatherData = this.state.reportWeather.map(weather => {
                                    if (weather.weatherType != null) {
                                        let weatherTypes = [weather.weatherType.map(e => e).join(", "),
                                            weather.wind, weather.temperature];
                                        return weatherTypes
                                    } else {
                                        let weatherTypes = [weather.weatherType = '',
                                            weather.wind, weather.temperature];
                                        return weatherTypes
                                    }
                                }
                            )
                        }
                        this.setState({
                            newWeatherData: weatherData
                        }, () => console.log(this.state.newWeatherData))
                    }
                );

                return console.log(this.state.newWeatherData)
            }).catch((error) => {
            ;
        })
    }

    getReportWeather1() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/weather/simple/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    reportWeather1: res.data
                }, () => {
                    console.log(this.state.reportWeather1)
                })

                let weatherData = [];
                weatherData = this.state.reportWeather.map(weather => {
                        let weatherTypes = weather.weatherType.map(e => e).join(", ")
                        this.setState({
                            weatherDataTypes: weatherTypes
                        })
                    }
                )


            }).catch((error) => {
            ;
        })
    }

    getReportGeneralComments() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/generalcomment/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    reportGeneralComments: res.data
                }, () => this.state.reportGeneralComments);

                return res.data
            }).catch((error) => {
            ;
        })
    }

    getEmployeesCompressedListByDate(dateToSearch) {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/employeescompressedlist/' + dateToSearch + '/projects/' + this.props.projectId).then(res => {
            this.setState({
                reportEmployees: res.data
            }, () => this.state.reportEmployees);

            return res.data
        })
            .catch((error) => {
                ;
            })
    }

    getTagByName = async (tagName) => {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        try {
            const response = await fetchTools.get(`api/tag/name/${tagName}`);
            console.log(response)
            return response.data[0]?._id
        } catch (error) {
            console.log('error', error);
        }
    }

    getImages() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        console.log("getImages")
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.jwtToken
            }
        };

        this.setState({retreivedImages: []})

        const URL = `${process.env.REACT_APP_API_URL}/api/images/project/${this.props.projectId}/date/${this.props.reportDate}?show_in_report_imgs=1`
        const image = axios.get(URL, config).then(response => response.data);
        const dataPromise = image.then((data) => {

            if (Array.isArray(data)) {
                data.map(async imageItem => {
                    let tagId = await this.getTagByName('weatherreport')
                    if (!imageItem.tags.includes(tagId)) {
                        console.log(imageItem)
                        imageItem.imageLocation = await this.getS3SignUrlGetObject(imageItem.imageKey)
                        this.setState(prevState => ({
                            retreivedImages: [...prevState.retreivedImages, imageItem]
                        }), () => {

                        })
                    } else {
                        console.log(imageItem.tags)
                    }

                })
            }
        })
    }

    getLogo() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.jwtToken
            }
        };

        this.setState({retreivedLogo: []})

        const image = axios.get(process.env.REACT_APP_API_URL + "/api/logo/company/" + this.props.clientId, config).then(response => response.data)
        const dataPromise = image.then((data) => {

            if (Array.isArray(data)) {
                data.map(async logoItem => {
                    try {
                        logoItem.imageLocation = await this.getS3SignUrlGetObject(logoItem.imageKey)
                        this.setState(prevState => ({
                            retreivedLogo: [...prevState.retreivedLogo, logoItem]
                        }), () => {


                        })
                    } catch (err) {

                    }

                })

            }
        })

    }


    buildReportHeader() {
        var reportHeaderContent = ''

        const headers = [[{bold: false, text: this.props.t("Project")}, {
            bold: false,
            text: this.props.t("Report Date")
        }]];

        const data = [[this.props.project, moment(this.props.reportDate).format('DD MMMM YYYY')]]
        let content = {
            head: headers,
            body: data,
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineWidth: 0.5

            },
            startY: 140,
            theme: "grid",

        };

        reportHeaderContent = content
        return reportHeaderContent
    }

    buildWeatherSummary() {
        var weatherSummaryContent = ''

        const headers = [{bold: false, text: this.props.t("Weather")}, {
            bold: false,
            text: this.props.t("Temperature")
        }, {bold: false, text: this.props.t("Wind")}];

        const weatherData = this.state.newWeatherData

        weatherData.splice(0, 0, headers)

        weatherSummaryContent = weatherData
        return weatherSummaryContent
    }

    buildWeatherSummary1() {
        var weatherSummaryContent = ''


        if (this.state.reportWeather1.length) {
            const weatherData = this.state.reportWeather1
            const test = [{text: this.props.t('Weather') + ': ' + this.state.weatherDataTypes, style: 'subheader'},
                {text: this.props.t('Temperature') + ': ' + weatherData[0].temperature, style: 'subheader'},
                {text: this.props.t('Wind') + ': ' + weatherData[0].wind, style: 'subheader'}
            ]


            weatherSummaryContent = test
            return weatherSummaryContent
        }
    }

    buildContractedWork() {

        let combinedRows = []
        let subHeaderColor = '#e6e6e6'

        const titleHeader = [{bold: false, text: this.props.t("Contract Work"), colSpan: 5}, {}, {}, {}, {}];
        combinedRows.push(titleHeader)
        const xheaders = [{
            fillColor: subHeaderColor,
            bold: false,
            text: `${this.props.t('Building')} / ${this.props.t('Task')}, ${this.props.t('Material')}, ${this.props.t('Elevation')}`
        }, {fillColor: subHeaderColor, text: this.props.t("System")}, {
            fillColor: subHeaderColor,
            text: this.props.t("Level")
        }, {fillColor: subHeaderColor, bold: false, text: this.props.t("Operatives")}, {
            fillColor: subHeaderColor,
            text: this.props.t("Comments")
        }];
        combinedRows.push(xheaders)
        var compositeScheduledItemId;
        const data = this.state.reportContractedWork.map(elt => {
                let firstRow = []


                if (elt.elevation) {
                    var compositeElevation = elt.elevation.name
                } else {
                    var compositeElevation = ''
                }
                if (elt.level) {
                    var compositeLevel = elt.level.name
                } else {
                    var compositeLevel = ''
                }
                if (elt.system) {
                    var compositeSystem = elt.system.name
                } else {
                    var compositeSystem = ''
                }
                if (elt.comment.length > 0) {
                    var compositeComment = elt.comment
                } else {
                    var compositeComment = ''
                }
                if (elt.hasOwnProperty('task')) {
                    if (elt.task != null) {
                        var compositeTaskName = elt.task.taskName
                    } else {
                        var compositeTaskName = ''
                    }
                } else {
                    var compositeTaskName = ''
                }
                if (elt.hasOwnProperty('material')) {
                    if (elt.material != null) {
                        var compositeMaterialName = elt?.material?.materialName
                    } else {
                        var compositeMaterialName = ''
                    }
                } else {
                    var compositeMaterialName = ''
                }
                if (typeof elt.scheduledItemId === null) {
                    compositeScheduledItemId = elt.scheduledItem
                } else {
                    compositeScheduledItemId = elt?.scheduledItemId?.full_path
                }

                // for (var i = 0; i < elt.timesheetLogDetail.length; i++) {
                //   if (firstIteration) {
                //     firstEmployeeFullName = elt.timesheetLogDetail[i].user_id.name + " " + elt.timesheetLogDetail[i].user_id.lastName
                //     firstEmployeeNumberOfHours = helper.hoursMinutesAllocateTimeFormatted(elt.timesheetLogDetail[i].allocated)
                //     firstIteration = false
                //   }
                //   else {
                //     someSubsequentRows.push([{}, {}, {}, { text: elt.timesheetLogDetail[i].user_id.name + " " + elt.timesheetLogDetail[i].user_id.last_name }, { text: helper.hoursMinutesAllocateTimeFormatted(elt.timesheetLogDetail[i].allocated) }, {}])
                //   }

                // }

                var employeeDetails = elt.timesheetLogDetail.map((employee) => {
                    return {
                        columns: [{
                            width: 100,
                            text: employee.user_id.name + ' ' + employee.user_id.last_name + '\n'
                        }, {width: 60, text: helper.hoursMinutesAllocateTimeFormatted(employee.allocated)}]
                    }
                })

                // let firstRowSpan = elt.timesheetLogDetail.length

                firstRow = [{
                    text:
                    // format output
                        elt.building.building
                        + '\n'
                        + compositeTaskName + ' ' + compositeMaterialName
                        + '\n'
                        + compositeElevation,

                },
                    {
                        text:
                        compositeSystem,

                    },
                    {
                        text:
                        compositeLevel,

                    }, employeeDetails,
                    {
                        text:
                        compositeComment,

                    }
                ]

                combinedRows.push(firstRow)
                // console.log(someSubsequentRows)
                // for (var i = 0; i < someSubsequentRows.length; i++) {
                //   combinedRows.push(someSubsequentRows[i])
                // }

                console.log(combinedRows)
                return {combinedRows}
            }
        )
        return combinedRows
    }

    buildAdditionalWorkx() {

        let combinedRows = []
        let subHeaderColor = '#e6e6e6'

        const titleHeader = [{bold: false, text: this.props.t("Additional Work"), colSpan: 5}, {}, {}, {}, {}];
        combinedRows.push(titleHeader)
        const xheaders = [{
            fillColor: subHeaderColor,
            bold: false,
            text: `${this.props.t('Building')} / ${this.props.t('Task')}, ${this.props.t('Material')}, ${this.props.t('Elevation')}`
        }, {fillColor: subHeaderColor, text: this.props.t("System")}, {
            fillColor: subHeaderColor,
            text: this.props.t("Level")
        }, {fillColor: subHeaderColor, bold: false, text: this.props.t("Operatives")}, {
            fillColor: subHeaderColor,
            text: this.props.t("Comments")
        }];
        combinedRows.push(xheaders)
        var compositeScheduledItemId;
        const data = this.state.reportAdditionalWork.map(elt => {
                let firstRow = []
                let someSubsequentRows = []
                if (typeof elt.instruction != "undefined") {
                    var compositeInstruction = elt.instruction.userDefinedInstructionNumber === undefined ? "-" + ' | ' + elt.instruction.instructionTitle : elt.instruction.userDefinedInstructionNumber + ' | ' + elt.instruction.instructionTitle
                } else {
                    var compositeInstruction = ''
                }
                if (elt.elevation) {
                    var compositeElevation = elt.elevation.name
                } else {
                    var compositeElevation = ''
                }
                if (elt.level) {
                    var compositeLevel = elt.level.name
                } else {
                    var compositeLevel = ''
                }
                if (elt.system) {
                    var compositeSystem = elt.system.name
                } else {
                    var compositeSystem = ''
                }
                if (elt.comment.length > 0) {
                    var compositeComment = elt.comment
                } else {
                    var compositeComment = ''
                }
                if (elt.hasOwnProperty('task')) {
                    if (elt.task != null) {
                        var compositeTaskName = elt.task.taskName
                    } else {
                        var compositeTaskName = ''
                    }
                } else {
                    var compositeTaskName = ''
                }
                if (elt.hasOwnProperty('material')) {
                    if (elt.material != null) {
                        var compositeMaterialName = elt.material.materialName
                    } else {
                        var compositeMaterialName = ''
                    }
                } else {
                    var compositeMaterialName = ''
                }
                if (typeof elt.scheduledItemId === null) {
                    compositeScheduledItemId = elt.scheduledItem
                } else {
                    compositeScheduledItemId = elt.scheduledItemId.full_path
                }
                // let firstIteration = true
                // let firstEmployeeFullName = ''
                // let firstEmployeeNumberOfHours = ''
                // for (var i = 0; i < elt.timesheetLogDetail.length; i++) {
                //   if (firstIteration) {
                //     firstEmployeeFullName = elt.timesheetLogDetail[i].user_id.name + " " + elt.timesheetLogDetail[i].user_id.last_name
                //     firstEmployeeNumberOfHours = helper.hoursMinutesAllocateTimeFormatted(elt.timesheetLogDetail[i].allocated)
                //     firstIteration = false
                //   }
                //   else {
                //     someSubsequentRows.push([{}, {}, {}, { text: elt.timesheetLogDetail[i].user_id.name + " " + elt.timesheetLogDetail[i].user_id.last_name }, { text: helper.hoursMinutesAllocateTimeFormatted(elt.timesheetLogDetail[i].allocated) }, {}])
                //   }

                // }
                var employeeDetails = elt.timesheetLogDetail.map((employee) => {
                    return {
                        columns: [{
                            width: 100,
                            text: employee.user_id.name + ' ' + employee.user_id.last_name + '\n'
                        }, {width: 60, text: helper.hoursMinutesAllocateTimeFormatted(employee.allocated)}]
                    }
                })
                // let firstRowSpan = elt.timesheetLogDetail.length

                firstRow = [{
                    text:
                        this.props.t('Instruction') + ': ' + compositeInstruction
                        + '\n'
                        // format output
                        + elt.building.building
                        + '\n'
                        + compositeTaskName + ' ' + compositeMaterialName
                        + '\n'
                        + compositeElevation,

                },
                    {
                        text:
                        compositeSystem,

                    },
                    {
                        text:
                        compositeLevel,

                    }, employeeDetails,
                    {
                        text:
                        compositeComment,

                    }
                ]
                combinedRows.push(firstRow)
                console.log(someSubsequentRows)
                for (var i = 0; i < someSubsequentRows.length; i++) {
                    combinedRows.push(someSubsequentRows[i])
                }
                console.log(combinedRows)
                return combinedRows
            }
        )

        return combinedRows
    }

    buildAdditionalWork() {

        let combinedRows = []
        let subHeaderColor = '#e6e6e6'

        const titleHeader = [{bold: false, text: this.props.t("Additional Work"), colSpan: 6}, {}, {}, {}, {}, {}];
        combinedRows.push(titleHeader)
        const xheaders = [{
            fillColor: subHeaderColor,
            bold: false,
            text: `${this.props.t('Building')} / ${this.props.t('Task')}, ${this.props.t('Material')}, ${this.props.t('Elevation')}`
        }, {fillColor: subHeaderColor, text: this.props.t("System")}, {
            fillColor: subHeaderColor,
            text: this.props.t("Level")
        }, {
            fillColor: subHeaderColor,
            bold: false,
            text: this.props.t("Operatives"),
            colSpan: 2
        }, {}, {fillColor: subHeaderColor, text: this.props.t("Comments")}];
        combinedRows.push(xheaders)
        var compositeScheduledItemId;
        const data = this.state.reportAdditionalWork.map(elt => {
                let firstRow = []
                let someSubsequentRows = []
                if (typeof elt.instruction != "undefined") {
                    var compositeInstruction = elt.instruction.userDefinedInstructionNumber === undefined ? "-" + ' | ' + elt.instruction.instructionTitle : elt.instruction.userDefinedInstructionNumber + ' | ' + elt.instruction.instructionTitle
                } else {
                    var compositeInstruction = ''
                }
                if (elt.elevation) {
                    var compositeElevation = elt.elevation.name
                } else {
                    var compositeElevation = ''
                }
                if (elt.level) {
                    var compositeLevel = elt.level.name
                } else {
                    var compositeLevel = ''
                }
                if (elt.system) {
                    var compositeSystem = elt.system.name
                } else {
                    var compositeSystem = ''
                }
                if (elt.comment.length > 0) {
                    var compositeComment = elt.comment
                } else {
                    var compositeComment = ''
                }
                if (elt.hasOwnProperty('task')) {
                    if (elt.task != null) {
                        var compositeTaskName = elt.task.taskName
                    } else {
                        var compositeTaskName = ''
                    }
                } else {
                    var compositeTaskName = ''
                }
                if (elt.hasOwnProperty('material')) {
                    if (elt.material != null) {
                        var compositeMaterialName = elt.material.materialName
                    } else {
                        var compositeMaterialName = ''
                    }
                } else {
                    var compositeMaterialName = ''
                }
                if (typeof elt.scheduledItemId === null) {
                    compositeScheduledItemId = elt.scheduledItem
                } else {
                    compositeScheduledItemId = elt.scheduledItemId.full_path
                }
                let firstIteration = true
                let firstEmployeeFullName = ''
                let firstEmployeeNumberOfHours = ''
                for (var i = 0; i < elt.timesheetLogDetail.length; i++) {
                    if (firstIteration) {
                        firstEmployeeFullName = elt.timesheetLogDetail[i].user_id.name + " " + elt.timesheetLogDetail[i].user_id.last_name
                        firstEmployeeNumberOfHours = helper.hoursMinutesAllocateTimeFormatted(elt.timesheetLogDetail[i].allocated)
                        firstIteration = false
                    } else {
                        someSubsequentRows.push([{}, {}, {}, {text: elt.timesheetLogDetail[i].user_id.name + " " + elt.timesheetLogDetail[i].user_id.last_name}, {text: helper.hoursMinutesAllocateTimeFormatted(elt.timesheetLogDetail[i].allocated)}, {}])
                    }

                }

                let firstRowSpan = elt.timesheetLogDetail.length

                firstRow = [{
                    text:
                        this.props.t('Instruction') + ': ' + compositeInstruction
                        + '\n'
                        // format output
                        + elt.building.building
                        + '\n'
                        + compositeTaskName + ' ' + compositeMaterialName
                        + '\n'
                        + compositeElevation,
                    rowSpan: firstRowSpan
                },
                    {
                        text:
                        compositeSystem,
                        rowSpan: firstRowSpan
                    },
                    {
                        text:
                        compositeLevel,
                        rowSpan: firstRowSpan
                    }, {text: firstEmployeeFullName}, {text: firstEmployeeNumberOfHours},
                    {
                        text:
                        compositeComment,
                        rowSpan: firstRowSpan
                    }
                ]
                combinedRows.push(firstRow)
                console.log(someSubsequentRows)
                for (var i = 0; i < someSubsequentRows.length; i++) {
                    combinedRows.push(someSubsequentRows[i])
                }
                console.log(combinedRows)
                return combinedRows
            }
        )

        return combinedRows
    }

    toMinutes(duration) {
        var ary = duration.split('.');
        return +Number(ary[0]) * 60 + Number(ary[1]);

    }

    sum(a, b) {
        return a + b;
    }

    buildContractorSummary() {
        var contractorSummaryContent = ''


        const headers = [{bold: false, text: this.props.t("Contractor Name")}, {
            bold: false,
            text: this.props.t("Number of Operatives")
        }, {text: this.props.t("Hours on Site")}];

        const data = this.state.reportContractorSummary.map(elt =>
            [elt._id.name,
                elt.count,
                helper.convertMinutesToHoursMinutes(this.state.reportContractorSummary[0].employeesWithTimesheet.filter(item => item.companyid[0] === elt._id._id).map((d) => this.toMinutes(d.lengthTime)).reduce(this.sum, 0))
            ]);

        data.splice(0, 0, headers)
        contractorSummaryContent = data
        return contractorSummaryContent
    }


    buildPositionSummary() {
        var positionsSummaryContent = ''

        const headers = [{bold: false, text: this.props.t("Position")}, {
            bold: false,
            text: this.props.t("Company")
        }, {bold: false, text: this.props.t("Number of Operatives")}];
        const data = this.state.reportPositions.map(elt =>
            [elt.position,
                elt.company,
                elt.count,
            ]);

        var total = 0;
        for (var i = 0, len = data.length; i < len; i++) {
            total += data[i][2];

        }
        data.splice(0, 0, headers)
        data.splice(data.length, 0, [{text: ""}, {bold: false, text: this.props.t("Total")}, {
            bold: false,
            text: total
        }])
        positionsSummaryContent = data
        return positionsSummaryContent
    }

    buildDelaySummary() {
        var delaySummaryContent = ''

        const headers = [{text: this.props.t("Delay Description"), bold: false}, {
            text: this.props.t("Comment"),
            bold: false
        }];

        const data = this.state.reportDelays.map(elt =>
            [elt.delayTypeDescription,
                elt.comment,
            ]);
        data.splice(0, 0, headers)
        delaySummaryContent = data
        return delaySummaryContent
    }

    buildRentalSummary() {
        const headers = [{text: this.props.t("Rental Company")}, {text: this.props.t("Equipment")}];
        
        const data = [];
        data.push(headers);

        if (Array.isArray(this.state.reportRentals)) {
            this.state.reportRentals.forEach(elt => {
                data.push([
                    elt?.rentalCompany?.name || '',
                    `${elt?.rentalEquipmentId?.description || ''} ${elt?.rentalEquipmentId?.typeModel || ''}`.trim()
                ]);
            });
        }

        return data;
    }

    buildGeneralComments(doc) {
        var generalCommentsContent = ''

        const headers = [{bold: false, text: this.props.t("General Comments")}];

        const data = this.state.reportGeneralComments.map(elt =>
            [elt.generalComment
            ]);
        data.splice(0, 0, headers)
        generalCommentsContent = data
        return generalCommentsContent
    }

    buildWeather() {
        var weatherContent = ''

        const headers = [[this.props.t("Weather"), this.props.t("Temperature"), this.props.t("Wind")]];

        const data = this.state.reportWeather.map(elt =>
            [elt.weatherType, elt.temperature, elt.wind
            ]);
        let content = {
            head: headers,
            body: data,
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineWidth: 0.5

            },

            theme: "grid"
        };

        weatherContent = content
        return weatherContent
    }


    buildTitles(title) {
        const headers = [[title]];

        const data = []

        let content = {
            head: headers,
            body: data,
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineWidth: 0

            },

            theme: "grid"
        };


        return content
    }

    getPDF = async () => {
        this.warningWithConfirmAndCancelMessage()
        this.setState({pdfWaitAlert: true})
        let weatherData = []
        let weatherData1 = []
        weatherData = this.buildWeatherSummary()
        weatherData1 = this.buildWeatherSummary1()
        let rentalData = []
        rentalData = this.buildRentalSummary()
        let contractedWorkData = []
        contractedWorkData = this.buildContractedWork()
        let contractorSummaryData = []
        contractorSummaryData = this.buildContractorSummary()
        let positionsSummaryData = []
        positionsSummaryData = this.buildPositionSummary()
        let additionalWorkData = []
        additionalWorkData = this.buildAdditionalWorkx()
        let delaySummaryData = []
        delaySummaryData = this.buildDelaySummary()
        let generalCommentsData = []
        generalCommentsData = this.buildGeneralComments()
        let projectName = this.props.project
        let clientName = this.props.client
        let reportDate = moment(this.props.reportDate).format('DD MMM YYYY')
        var temp = ''
        if (this.state.reportWeather1.length > 0) {
            temp = this.state.reportWeather1[0].temperature
        }
        var windSpeed = ''
        if (this.state.reportWeather1.length > 0) {
            windSpeed = this.state.reportWeather1[0].wind
        }
        //////////////////AddLOGO SOMEWHERE/////////////////////


        /////////////////////END LOGO WORK//////////////////////////////////

        let docDefinitions = {
            // header: 'C#Corner PDF Header',
            compress: true,
            pageMargins: [40, 40, 40, 60],
            content: [],
            pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
            },

            defaultStyle: {
                font: 'calibre'
            },

            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader1: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                subheader2: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                tableExample: {
                    margin: [0, 5, 0, 15],
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
        }


        pdfMake.fonts = {
            calibre: {
                normal: 'Calibre-Regular.ttf',
                bold: 'Calibre-Semibold.ttf',
                italics: 'Calibre-RegularItalic.ttf',
                bolditalics: 'Calibre-SemiboldItalic.ttf'
            },
        }

        pdfMake.tableLayouts = {
            standardTable: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0.25;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                },
                vLineWidth: function (i) {

                    return 0.25;
                },
                fillColor: function (i, node) {
                    if (i === 0) {
                        return '#CCCCCC'
                    }
                },
                hLineColor: function (i) {
                    return i === 1 ? '#878787' : '#aaa';
                },

                // vLineColor: function(i, node) {
                //   return (i === 0 || i === node.table.widths.length) ? '#ccc' : '#ccc';},
                paddingTop: function (i) {
                    return i === 0 ? 10 : 5;
                },
                paddingBottom: function (i, node) {
                    return i === 0 ? 5 : 3;
                },
                margin: [0, 0, 0, 0]
            },

            verticalHeaders: {
                hLineWidth: function (i, node) {

                    return 0.25;

                },
                vLineWidth: function (i, node) {
                    // Always show outer borders (left and right sides)
                    if (i === 0 || i === node.table.widths.length) {
                        return 1;
                    }
                    // Show middle vertical line for first two rows
                    if (i === 1 && (node.table.body.length > 0)) {
                        return 1;
                    }
                    return 0;
                },
                vLineColor: function (rowIndex, node, columnIndex) {
                    if (columnIndex === 0) {
                        return '#878787';
                    }
                    return '#aaa'

                },

                fillColor: function (rowIndex, node, columnIndex) {
                    return (columnIndex === 0) ? '#CCCCCC' : null;
                },
            }
        };

        var filteredLogos = []
        this.state.retreivedLogo.map(logo => {
            filteredLogos.push(logo)
        })


        var logoContainer = []
        for (let filterLogo of filteredLogos) {
            try {
                const logoData = await this.toDataURLLogos(filterLogo.imageLocation)




                var logoToAdd = {
                    image: logoData,
                    width: 200,
                    margin: 20,
                    // alignment: 'center',
                }

                logoContainer.push(logoToAdd)
            } catch (err) {
                console.log(err)
            }

        }

        if (logoContainer.length > 0) {
            const logoContent = {
                image: logoContainer[0].image,
                margin: [0, 15, 0, 0],
                width: 140,
                alignment: 'right'
            }
            docDefinitions.content.push(logoContent)
        }

        docDefinitions.content.push(basicShell)
        const basicShell = [

            {
                columns: [
                    {
                        text: this.props.t('Daily Site Report'), style: {
                            fontSize: 24,
                            bold: true,
                            color: "gray",
                            marginBottom: 2
                        }, lineHeight: 2
                    },

                ],
                columnGap: 40,
            },
            {text: "\n"},
            {text: "\n"},
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0, y1: 0,
                        x2: 510, y2: 0,
                        lineWidth: 0.25
                    }
                ]
            },
            {text: "\n"}, {
                columns: [
                    {
                        style: 'tableExample',
                        layout: 'verticalHeaders',
                        table: {
                            // headerRows: 1,

                            widths: [70, 170],
                            body:
                                [
                                    [{text: this.props.t('Company')}, {text: clientName, style: 'subheader'}],
                                    [{text: this.props.t('Project'), style: 'subheader'},
                                        {text: this.props.project, style: 'subheader'}
                                    ],

                                    [{text: this.props.t('Date')}, {text: reportDate, style: 'subheader'}]

                                ]
                        },
                        margin: [0, 0, 0, 20],
                        dontBreakRows: false
                    },
                    {
                        style: 'tableExample',
                        layout: 'verticalHeaders',
                        table: {
                            // headerRows: 1,

                            widths: [70, 170],
                            body:
                                [
                                    [{text: this.props.t('Weather')}, {
                                        text: this.state.weatherDataTypes,
                                        style: 'subheader'
                                    }],
                                    [{text: this.props.t('Temperature'), style: 'subheader'},
                                        {text: temp, style: 'subheader'}
                                    ],

                                    [{text: this.props.t('Wind')},
                                        {text: windSpeed, style: 'subheader'}]

                                ]
                        },
                        margin: [0, 0, 0, 20],
                        dontBreakRows: false
                    },
                ]
            },


            {

                layout: 'standardTable',
                table: {
                    // headerRows: 1,
                    widths: ['*', '*', '*'],
                    body:
                    contractorSummaryData,
                },
                // layout: 'lightHorizontalLines',
                margin: [0, 0, 0, 20],
                dontBreakRows: true,
            },

        ]

        docDefinitions.content.push(basicShell)

        const positionsSummaryDataSection = {
            layout: 'standardTable',
            table: {
                // headerRows: 1,
                widths: ['*', '*', '*'],
                body:
                positionsSummaryData,
            },
            // layout: 'lightHorizontalLines',
            margin: [0, 0, 0, 20],
            dontBreakRows: true,
        }
        if (positionsSummaryData.length > 1) {
            docDefinitions.content.push(positionsSummaryDataSection)
        }
        // ///////////////////////


        const contractorSummarySection = [
            {
                // columns: [
                //
                //   {
                //     style: 'tableExample',
                //     table: {
                //       // headerRows: 1,
                //       widths: ['*', '*', '*'],
                //       body:
                //         positionsSummaryData,
                //     },
                //     // layout: 'lightHorizontalLines',
                //     margin: [0, 0, 0, 20],
                //     dontBreakRows: true,
                //   }
                // ],
                // columnGap: 10
            }

        ]

        docDefinitions.content.push(contractorSummarySection)

        //RENTAL DATA
        const rentalDataSection = {
            layout: 'standardTable',
            table: {
                // headerRows: 1,

                widths: ['*', '*'],
                body:
                rentalData,
            },
            // layout: 'lightHorizontalLines',
            margin: [0, 0, 0, 20],
            dontBreakRows: true
        }
        if (rentalData.length > 1) {
            docDefinitions.content.push(rentalDataSection)
        }

        const ContractedWorkDataSection = {
            // style: 'tableExample',
            layout: 'standardTable',
            table: {
                // headerRows: 2,

                widths: ['auto', 'auto', 'auto', 160, '*'],
                body:
                contractedWorkData,
                // body:

                //   combinedRows

            },
            margin: [0, 0, 0, 20],
            dontBreakRows: false,
            keepWithHeaderRows: 2
        }
        if (contractedWorkData.length > 2) {

            docDefinitions.content.push(ContractedWorkDataSection)
        }


        const additionalWorkDataSection =
            {
                layout: 'standardTable',
                // style: 'tableExamples',
                table: {
                    // headerRows: 2,

                    widths: ['auto', 'auto', 'auto', 160, '*'],
                    body:
                    additionalWorkData,

                },
                // layout: 'lightHorizontalLines',
                margin: [0, 0, 0, 20],
                dontBreakRows: true,
                keepWithHeaderRows: 2
            }

        if (additionalWorkData.length > 2) {
            docDefinitions.content.push(additionalWorkDataSection)
        }

        const delaySummaryDataSection = {
            style: 'tableExamples',
            layout: 'standardTable',
            table: {
                // headerRows: 1,
                widths: ['*', '*'],
                body:
                delaySummaryData,
            },
            // layout: 'lightHorizontalLines',
            margin: [0, 0, 0, 20],
            dontBreakRows: true
        }
        if (delaySummaryData.length > 1) {
            docDefinitions.content.push(delaySummaryDataSection)
        }


        // let combinedRows=[]
        // combinedRows.push([{text:'1'},{text:'TwoTwo', colSpan:2}, {}],)
        // combinedRows.push([{text:'rowspan2', rowSpan:2}, {text:'test'}, {text:'test'}])
        // combinedRows.push( [{}, {text:'test'}, {text:'test'}])


        const generalCommentsSection = {
            style: 'tableExamples',
            layout: 'standardTable',
            table: {
                // headerRows: 1,
                widths: ['*'],
                body:
                generalCommentsData,
            },
            // layout: 'lightHorizontalLines',
            dontBreakRows: true
            // pageBreak: 'after'
        }
        if (generalCommentsData.length > 1) {
            docDefinitions.content.push(generalCommentsSection)
        }

        var photoTitle = {text: this.props.t('Photographs'), style: 'header', lineHeight: 2, pageBreak: 'before'}
        docDefinitions.content.push(photoTitle)

        //for each item in selected images
        // Select location from retreivedImages where id == selectedImages.id


        var line = {
            canvas: [
                {
                    type: 'line',
                    x1: 0, y1: 0,
                    x2: 510, y2: 0,
                    lineWidth: 0.25
                }
            ]
        }

        docDefinitions.content.push(line)

        //   //second attempt
        var filteredImages = []
        this.state.retreivedImages.map(image => {

            filteredImages.push(image)

        })

        //   )
        var self = this
        var container = []
        for (let filterImage of filteredImages) {

            try {
                const imagedata = await this.toDataURL1(filterImage.imageLocation)
                // var imageToAdd = {}
                var metaDataAvailable = ((filterImage || {}).metadata || {}).width;
                let imageWidth = 0
                let imageHeight = 0
                if (metaDataAvailable) {
                    let imageSizing = imageHelper.calculateAspectRatioFit(filterImage.metadata.width, filterImage.metadata.height, 200, 300)
                    imageWidth = imageSizing.width
                    imageHeight = imageSizing.height
                } else {
                    imageWidth = 200
                    imageHeight = 300
                }
                var imageToAdd = {
                    stack: [
                        // second column consists of paragraphs
                        {
                            image: imagedata,
                            width: imageWidth,
                            height: imageHeight,
                            margin: [20, 10, 10, 10],
                        },
                        {text: filterImage.imageComment, margin: [20, 0, 0, 0],},
                    ],
                }
                container.push(imageToAdd)


            } catch (err) {
                console.log(err)
            }
        }

        var a = container, chunk
        while (a.length > 0) {

            chunk = a.splice(0, 2)
            docDefinitions.content.push({
                columns: [
                    chunk[0],
                    chunk[1]
                ],
                columnGap: 40,
            })

        }

        docDefinitions.footer = function(currentPage, pageSize) {
            // Check if this is the last page
            const isLastPage = currentPage === pageSize;
            
            return {
                margin: [0, -10, 0, 0], 
                stack: [
                    // First item: Table with border (only on the last page)
                    ...(isLastPage ? [{
                        margin: [15, -25, 15, 55], 
                        table: {
                            widths: ['50%', '50%'],  // Two equal columns
                            body: [
                                // First row
                                [
                                    {
                                        text: 'Documented by:',
                                        alignment: "left",
                                        fontSize: 10
                                    },
                                    {
                                        text: 'Handed over to:',
                                        alignment: "left",
                                        fontSize: 10
                                    }
                                ],
                                // Second row
                                [
                                    {
                                        text: '-', // required to keep row height
                                        alignment: "left",
                                        fontSize: 10,
                                        color: 'white'
                                      
                                    },
                                    {
                                        text: '-', // required to keep row height
                                        alignment: "right",
                                        fontSize: 10,
                                        color: 'white'
                                    }
                                ],
                                // Third row
                                [
                                    {
                                        colSpan: 2,
                                        table: {
                                            widths: ['25%', '25%', '25%', '25%'], // Four equal columns
                                            body: [
                                                [
                                                    {
                                                        text: 'Date',
                                                        alignment: "center",
                                                        fontSize: 10
                                                    },
                                                    {
                                                        text: 'Site Manager',
                                                        alignment: "center",
                                                        fontSize: 10
                                                    },
                                                    {
                                                        text: 'Date',
                                                        alignment: "center",
                                                        fontSize: 10
                                                    },
                                                    {
                                                        text: 'Signature Client\'s Representative',
                                                        alignment: "center",
                                                        fontSize: 10
                                                    }
                                                ]
                                            ]
                                        },
                                        layout: {
                                            hLineWidth: function() { return 0; },
                                            vLineWidth: function() { return 0; },
                                            vLineColor: function() { return '#aaa'; }
                                        }
                                    },
                                    {}
                                ]
                            ]
                        },
                        layout: {
                            hLineWidth: function(i, node) {
                                return (i === 0 || i === node.table.body.length || i === 2) ? 1 : 0;
                            },
                            vLineWidth: function(i, node) {
                                // Always show outer borders (left and right sides)
                                if (i === 0 || i === node.table.widths.length) {
                                    return 1;
                                }
                                // Show middle vertical line for first two rows
                                if (i === 1 && (node.table.body.length > 0)) {
                                    return 1;
                                }
                                return 0;
                            },
                            hLineColor: function() {
                                return '#aaa';
                            },
                            vLineColor: function() {
                                return '#aaa';
                            },
                            fillColor: function() {
                                return null;
                            }
                        }
                    }] : []),
                    
                    // Regular footer content (on all pages)
                    {
                        margin: [15, isLastPage ? -50 : 0, 15, 0],
                        columns: [{
                            alignment: "left",
                            text: 'Daily Report - ' + moment(self.props.reportDate).format('DD MMM YYYY') + '\n' + self.props.project,
                            color: "gray",
                            fontSize: 10
                        }, {
                            alignment: "center",
                            text: [
                                {text: "Page " + currentPage.toString()},
                                " of ",
                                {text: pageSize.toString()}
                            ], color: "gray", fontSize: 10
                        }, {
                            alignment: "right",
                            text: [
                                {
                                    text: "Prepared by Worksite Manager\non " + moment().format('DD MMM YYYY'),
                                    color: "gray",
                                    fontSize: 10
                                },
                            ]
                        }]
                    }
                ]
            };
        };


        self.setState({
            pdfWaitAlert: false
        }, () => {
            this.hideAlert()
        })
        pdfMake.createPdf(docDefinitions).download('Daily Site Report - ' + this.props.project + ' ' + this.props.reportDate + '.pdf');

    }

    async getBase64(url) {
        return fetch
        (url, {
            crossDomain: true,
            method: 'get',
            mode: 'cors' // 'cors' by default
        })
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))

    }


    addToSelectedImagesList(addImageID) {

        this.setState({
            selectedImageList: [
                ...this.state.selectedImageList,
                addImageID
            ]
        }, () => {
            console.log(this.state.selectedImageList)
        });

    }

    removeFromSelectedImagesList(removeImageID) {
        this.setState({
            selectedImageList: this.state.selectedImageList.filter(function (image) {
                return image !== removeImageID
            })
        }, () => {
            console.log(this.state.selectedImageList)
        })
    }

    selectImagesToDisplayInReport() {

    }


    async toDataURL1(src, outputFormat) {
        try {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    var canvas = document.createElement('CANVAS');
                    var ctx = canvas.getContext('2d');
                    var dataURL;
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    console.log(this)
                    ctx.drawImage(this, 0, 0);

                    try {

                        dataURL = imageConversion.canvastoDataURL(canvas, 0.4)


                        // dataURL = canvas.toDataURL(null);


                    } catch (error) {
                        console.error(error);
                    }

                    try {
                        resolve(dataURL);
                    } catch (error) {
                        console.error(error);
                    }
                };

                img.src = src;
                if (img.complete || img.complete === undefined) {
                    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                    img.src = src;
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    async toDataURLLogos(src, outputFormat) {
        try {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    var canvas = document.createElement('CANVAS');
                    var ctx = canvas.getContext('2d');
                    var dataURL;
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    console.log(this)
                    ctx.drawImage(this, 0, 0);

                    try {

                        dataURL = canvas.toDataURL();

                    } catch (error) {
                        console.error(error);
                    }
                    try {
                        resolve(dataURL);
                    } catch (error) {
                        console.error(error);
                    }
                };

                img.src = src;
                if (img.complete || img.complete === undefined) {
                    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                    img.src = src;
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    deleteRentalEquipmentAlert(rentalId) {
        console.log(rentalId)
        this.setState({
            deleteRentalEquipmentAlert:
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    onConfirm={() => this.deleteRentalEquipmentById(rentalId?._id)}
                    onCancel={() => this.setState({ deleteRentalEquipmentAlert: null }, () => { })}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={this.props.t("Delete")}
                    cancelBtnText={this.props.t("Cancel")}
                    showCancel
                >
                    {this.props.t("Delete Rental Equipment")}
                    <br/>
                    {`${rentalId?.rentalCompany?.name || ''} | ${rentalId?.description || ''} | ${rentalId?.typeModel || ''}`}
                </ReactBSAlert>
        }, () => { })
    }

    render() {
        if (userCant(this.props.user, 'siteReport.manage')) {
            return
        }

        const images = this.state.retreivedImages

        return (
            <>
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col md='1'></Col>
                        <Col md='10'>
                            <Card id="pdfdiv">
                                <CardBody>
                                    <Row>
                                        <Col md="10"></Col>
                                        <Col md="2">
                                            <Button onClick={this.getPDF} variant="contained" color="primary">
                                                {this.props.t('Generate Pdf')}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="10"></Col>
                                        <Col md="2">
                                            {/* <img src={require('../images/logo-skyklass.png')} /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label>{this.props.t('Site Diary for ')}{moment(this.props.reportDate).format('DD MMMM YYYY')}</label>
                                        </Col>
                                    </Row>
                                    <Row><Col>
                                        <SiteReportTable/>
                                    </Col></Row>
                                    <Row><Col>
                                        <SiteReportTitleandWeather
                                            reportWeather={this.state.reportWeather}
                                        />
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <SiteReportContractorSummary
                                                reportContractorSummary={this.state.reportContractorSummary}
                                            />
                                        </Col>
                                        <Col md='6'>
                                            <SiteReportPositions
                                                reportPositions={this.state.reportPositions}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SiteReportContractors
                                                formattedTableStructure={this.state.formattedTableStructure}
                                                reportContractedWork={this.state.reportContractedWork}
                                                reportEmployees={this.state.reportEmployees}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SiteReportDelays
                                                reportDelays={this.state.reportDelays}
                                                getReportDelays={this.getReportDelays}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SiteReportAdditionalWorks
                                                formattedAdditionalTableStructure={this.state.formattedAdditionalTableStructure}
                                                reportAdditionalWork={this.state.reportAdditionalWork}
                                                reportEmployees={this.state.reportEmployees}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SiteReportRentals
                                                reportRentals={this.state.reportRentals}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SiteReportGeneralComments
                                                reportGeneralComments={this.state.reportGeneralComments}
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                    <Col md="12">
                      <h4 className="pl-3 card-title">Images</h4>
                      <ImageGallery
                        images={images}
                        limit={12}
                        addToSelectedImagesList={this.addToSelectedImagesList}
                        removeFromSelectedImagesList={this.removeFromSelectedImagesList}
                      />
                    </Col>
                  </Row> */}
                                    <Row>
                                        <Col md="10"></Col>
                                        <Col md="2">
                                            <Button onClick={
                                                this.getPDF
                                            } variant="contained" color="primary">
                                                {this.props.t("Generate Pdf")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='1'></Col>
                    </Row>

                </div>
            </>
        );
    }
}

const SiteReportNextGenFour = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedSiteReportNextGenFour));

export default SiteReportNextGenFour;

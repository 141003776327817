
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportTitleandWeather extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      weatherObtained:"",
      weatherTemp7am:"",
      weatherTemp1pm:"",
      windSpeed7am:"",
      windSpeed1pm:""
    }
  }

  createWeatherConditions(weatherConditions) {
    if (Array.isArray(weatherConditions) && weatherConditions.length) {
      var weather = "";
      var sun = ((weatherConditions[0].weatherConditionsSun==="on"|weatherConditions[0].weatherConditionsSun===1)?this.props.t('Sun'):'');
      var rain = ((weatherConditions[0].weatherConditionsRain==="on"|weatherConditions[0].weatherConditionsRun===1)?this.props.t('Rain'):'');
      var cloud = ((weatherConditions[0].weatherConditionsCloud==="on"|weatherConditions[0].weatherConditionsCloud===1)?this.props.t('Cloud'):'');
      var storm = ((weatherConditions[0].weatherConditionsStorm==="on"|weatherConditions[0].weatherConditionsStorm===1)?this.props.t('Storm'):'');
      var freezing = ((weatherConditions[0].weatherConditionsFreezing==="on"|weatherConditions[0].weatherConditionsFreezing===1)?this.props.t('Freezing'):'');
      var freezing = ((weatherConditions[0].temp7am==="on"|weatherConditions[0].weatherConditionsFreezing===1)?this.props.t('Freezing'):'');
      weather=sun+rain+cloud+storm+freezing
      var temp7am=weatherConditions[0].temp7am
      var temp1pm=weatherConditions[0].temp1pm
      var wind7am=weatherConditions[0].wind7am
      var wind1pm=weatherConditions[0].wind1pm
      this.setState({ 
        weatherObtained: weather, 
        weatherTemp7am:temp7am,
        weatherTemp1pm:temp1pm,
        windSpeed7am:wind7am,
        windSpeed1pm:wind1pm,
       }, ()=>this.state)

    }
    else
    {
      this.setState({ weatherObtained: [] }, ()=>this.state)
    }
  }

  createSimpleWeatherConditions(weatherConditions) {
    if (Array.isArray(weatherConditions) && weatherConditions.length) {
    
      this.setState({ 
        weatherType: weatherConditions[0].weatherType, 
        temperature:weatherConditions[0].temperature,
        wind:weatherConditions[0].wind,
       }, ()=>this.state)

    }
    else
    {
      this.setState({ weatherType:[], 
        temperature:[],
       wind:[] }, ()=>this.state)
    }
  }


  


  componentWillReceiveProps({ reportWeather }) {
  
    this.setState({ ...this.state, reportWeather }, () => this.createSimpleWeatherConditions(reportWeather))
  }



  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col>
             
            </Col>
            <Col>
            <Row>
                <Col md="3">{this.props.t('Weather Conditions')}:</Col>

                <Col>{this.state.weatherObtained}</Col>
              </Row>
              <Row>

                <Col >
                  {this.props.t('Weather Types')}: {this.state.weatherType}
                </Col>
                <Col >
                  {this.props.t('Temperature')}: {this.state.temperature}
                </Col>
                <Col >
                  {this.props.t('Wind')}: {this.state.wind}
                </Col>
              </Row>
             
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const SiteReportTitleandWeather = withTranslation()( connect(  mapStateToProps,  null)(ConnectedSiteReportTitleandWeather));

export default SiteReportTitleandWeather;
